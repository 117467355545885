import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import * as THREE from 'three';
import { AvatarLoaderService } from '../../avatar/avatar-loader.service';
import { ThreeClockService } from '../../utils/three-clock.service';
import { GazeInfo, ThreeAvatar } from 'src/app/models/three-avatar.model';
import { AzureLipsyncService } from '../../lipsync/azure-lipsync.service';
import { CameraMovementService } from '../camera/camera-movement.service';

@Injectable({
  providedIn: 'root'
})
export class HeadMovementService {
  subscriptions$: Subscription[] = [];
  private baseHead!: THREE.Object3D;
  private targetPoint!: THREE.Object3D;
  private targetCenter!: THREE.Vector3;
  private nextHeadPosition!: THREE.Vector3;
  private headCenterForward: THREE.Vector3 = new THREE.Vector3();
  private headMovementSpeed = 0.05;
  private startExecuted = false;

  private maxTime = 5;
  private startTime = 0;

  private idleRadius: THREE.Vector3 = new THREE.Vector3(1, 0, 0);
  private canMoveHead = true;
  private isMovingHead = false;
  private isReseting = false;

  constructor(private avatarLoaderService: AvatarLoaderService, private clockService: ThreeClockService,
    private azureLipsyncService: AzureLipsyncService, private cameraService: CameraMovementService) {
    this.initServices();
  }

  private initServices() {
    this.subscriptions$.push(
      this.avatarLoaderService.getAvatarInfo().subscribe((avatarInfo: ThreeAvatar) => {
        this.start(avatarInfo.gazeInfo)
      }),
      this.azureLipsyncService.getLipsyncState().subscribe((lipsync: boolean) => {
        if (lipsync) {
          this.updateTargetPosition(true);
        }
        else {
          this.canMoveHead = true;
        }
      }),
    );
  }

  private start(gazeInfo: GazeInfo) {
    this.baseHead = gazeInfo.baseHead;
    this.baseHead.clone().getWorldDirection(this.headCenterForward);
    this.startExecuted = true;
  }

  public setTargetPoint(target: THREE.Object3D) {
    this.targetPoint = target;
    this.targetCenter = this.targetPoint.clone().position;
  }

  public update() {
    if (!this.startExecuted) return;
    // this.updateTargetPosition(false);
    this.RotateHead();
    this.baseHead.lookAt(this.targetPoint.position);
  }

  private updateTargetPosition(reset: boolean) {
    if (!this.canMoveHead) return;

    if (reset) {
      this.nextHeadPosition = new THREE.Vector3(this.targetCenter.x, this.targetCenter.y, this.targetCenter.z);
      this.isMovingHead = true;
      this.isReseting = true;
    }

    else if (this.clockService.clock.getElapsedTime() > (this.startTime + this.maxTime) && !this.isReseting && !this.isMovingHead) {
      const xRadius = Math.random() * 2 * this.idleRadius.x - this.idleRadius.x;
      const yRadius = Math.random() * 2 * this.idleRadius.y - this.idleRadius.y;
      const zRadius = Math.random() * 2 * this.idleRadius.z - this.idleRadius.z;
      this.nextHeadPosition = new THREE.Vector3(this.targetCenter.x + xRadius, this.targetCenter.y + yRadius, this.targetCenter.z + zRadius);
      this.isMovingHead = true;
    }
  }

  private RotateHead() {
    if (!this.canMoveHead || !this.isMovingHead) return;
    
    const distanceToTarget = this.nextHeadPosition.distanceTo(this.targetPoint.position);
    if (distanceToTarget > 0.06) {
      this.targetPoint.position.lerp(this.nextHeadPosition, this.headMovementSpeed);
    }
    else {
      this.targetPoint.position.set(this.nextHeadPosition.x, this.nextHeadPosition.y, this.nextHeadPosition.z);
      this.isMovingHead = false;
      this.startTime = this.clockService.clock.getElapsedTime();
      if (this.isReseting) {
        this.canMoveHead = false;
        this.isReseting = false;
      }
    }
  }
}
