import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ParametrosURL } from '../../models/obtener-shorten-url';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShortenUrlService {

  private urlEnlaces = environment.transversales.urlEnlaces;

  constructor(private http: HttpClient) { }

  public getShortenUrl(id: string): Observable<ParametrosURL> {
    return this.http.get<ParametrosURL>(`${this.urlEnlaces}?guidEnlace=${id}`);
  }
}
