import { Injectable, ViewContainerRef } from '@angular/core';
import { AvatarData } from 'src/app/models/avatar-data.model';
import { DatumButtons, TenantData } from 'src/app/models/tenant-data.model';
import { TenantDataService } from '../tenant/tenant-data.service';
import { Engine } from 'src/app/enums/engine';
import { UseCasesService } from '../chat/use-cases.service';
import { WebSocketService } from '../web-socket.service';
import { UrlParams } from 'src/app/models/parameters.model';
import { TranslatorService } from '../translator.service';
import { EngineComponent } from 'src/app/components/engine/engine.component';
import { VideoEngineComponent } from 'src/app/components/engine/strategies/video-engine/video-engine.component';
import { BujaComponent } from 'src/app/components/threejs/models/buja/buja.component';
import { ThreejsAvatarsComponent } from 'src/app/components/threejs/threejs-avatars/threejs-avatars.component';
import { BundleDownloader } from '../bundle-downloader.service';
import { UnityControllerService } from '../unity-controller.service';
import { AvatarInteractionsService } from '../avatar/avatar-interactions.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { SpeechToTextService } from '../chat/speech-to-text.service';
import { LoadUnityService } from './load-unity.service';

@Injectable({
  providedIn: 'root'
})
export class EngineService {
  engineComponent!: EngineComponent;

  dataParams!: UrlParams;
  tenantData!: TenantData;
  avatarData!: AvatarData;
  avatarName!: string;
  engine: Engine = Engine.Unity;
  elderAdultLayout = false;
  tenantBlancox = false;
  noAvatar = false;
  inactivityTimeout: any;
  isSingleFlow = false;
  startButtonClicked = false
  volumeGeneral = 100;
  loading = true;

  isIOS = false;
  predeterminedMessage = false;
  avatarLoaded = false;
  subscriptions$: Subscription[] = [];
  userInteracted = false;
  welcomeTimeout: any;

  private centerAvatar = new Subject<boolean>();
  unityLoader!: LoadUnityService;

  constructor(
    private tenantDataService: TenantDataService,
    private webSocketService: WebSocketService,
    private bundleDownloader: BundleDownloader,
    private unityControllerService: UnityControllerService,
    private useCasesService: UseCasesService,
    private speechToTextService: SpeechToTextService,
    private avatarInteractionsService: AvatarInteractionsService,
    private translatorService: TranslatorService,
  ) { }

  initService(engineComponent: EngineComponent) {
    this.engineComponent = engineComponent;
    this.subscribeTenantDataService();
    this.checkIsIOS();
    this.addSubscriptions();
  }

  setStartPressedObservable(startPressedObservable: Observable<void | DatumButtons>) {
    startPressedObservable.subscribe(() => this.startPressed());
  }

  startPressed() {
    this.startButtonClicked = true;
    this.subscribeResetTimers();
    this.makeInitialGreeting();
  }

  private subscribeResetTimers() {
    this.subscribeResetInactivityByKey();
    this.subcribeResetInactivityByClick();
  }

  private subcribeResetInactivityByClick() {
    // Add listener to click event to know when the user has interacted with the page
    document.addEventListener('click', () => {
      if (!this.userInteracted) {
        this.userInteracted = true;
      }
      this.resetInactivityTimer();
      this.resetWelcomeTimeout();
    });
  }

  private subscribeResetInactivityByKey() {
    // Inactivity timer
    document.addEventListener('keypress', () => {
      this.resetInactivityTimer();
      this.resetWelcomeTimeout();
    });
  }

  resetWelcomeTimeout() {
    clearTimeout(this.welcomeTimeout);
    this.welcomeTimeout = setTimeout(() => {
      if (!this.userInteracted) return;
      this.sendWelcomeMessage();
      this.resetWelcomeTimeout();
    }, 180000);
  }

  sendWelcomeMessage() {
    if (this.dataParams.Tenant !== "EPM") return;
    if (this.startButtonClicked) {
      this.avatarInteractionsService.sendEPMWelcomeMessage();
      this.predeterminedMessage = true;
    }
  }

  addSubscriptions() {
    this.subscriptions$.push(
      this.subscribeWebSocketConnected(),
      this.subscribeUnityControllerSpeakState(),
      this.subscribeAvatarLoaded()
    );
  }

  removeSubscriptions() {
    this.subscriptions$.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  private subscribeTenantDataService() {
    this.subscribeDataParams();
    this.subscribeTenantData();
    this.subscribeAvatarData();
  }
  private subscribeWebSocketConnected(): Subscription {
    return this.webSocketService.getTokenEvent().subscribe(() => {
      // Get the volume from local storage and set it to the unity instance
      const volume = localStorage.getItem('volume');
      this.volumeGeneral = volume ? parseInt(volume) : 100;

      // Init the speech to text subscription
      this.speechToTextService.initSTT();
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    });
  }

  private subscribeDataParams() {
    this.tenantDataService.getDataParamsEvent().subscribe({
      next: (res: UrlParams) => {
        this.dataParams = res;
        this.startAppWithParams();
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  private subscribeTenantData() {
    this.tenantDataService.getDatosTenantEvent().subscribe({
      next: (res: TenantData) => {
        this.setTenantSettings(res);
      }, error: (err) => {
        console.error(err);
      }
    });
  }

  private setTenantSettings(res: any) {
    res.data.personaDigital.interfazUsuario.tipoFondo = res.data.personaDigital.interfazUsuario.tipoFondo.toString();
    this.tenantData = res;
    this.avatarName = this.tenantData.data.personaDigital.nombre;
  }

  private subscribeAvatarData() {
    this.tenantDataService.getAvatarDataLocal().subscribe({
      next: (res: AvatarData) => {
        this.setAvatarData(res);
      }, error: (err) => {
        console.error(err);
      }
    })
  }

  private setAvatarData(res: AvatarData) {
    this.avatarData = res;
    if (this.avatarData.data.description.toLocaleLowerCase().includes('buja')) {
      if (this.avatarData.data.description.toLocaleLowerCase().includes('buja')) {
        this.engine = Engine.Blancox;
        this.tenantBlancox = true;
        this.engineComponent.showLoading = false;
      }
    }
    else if (res.data.engine === "none" || res.data.engine === "null") {
      this.noAvatar = true;
      this.engineComponent.noAvatar = true;
    }
    else if (res.data.engine === 'threejs') {
      this.engine = Engine.Three;
    }
    else if (res.data.engine === 'talkinghead') {
      this.engine = Engine.Talkinghead;
    }
    this.loadWebFirst(this.dataParams);
  }

  startAppWithParams() {
    this.resetInactivityTimer();
    if (this.dataParams.Tenant === 'EPM') {
      this.elderAdultLayout = true;
    }
  }

  resetInactivityTimer() {
    clearTimeout(this.inactivityTimeout);
    this.inactivityTimeout = this.callInactivityTimeout();
  }

  private callInactivityTimeout() {
    const inactivityTime = this.dataParams.Canal === 'kiosco' ? 2 : 1440
    return setTimeout(() => {
      this.webSocketService.setEndConversation(true);
      this.useCasesService.setStopConversation(true);

      this.useCasesService.setMicState(-1);
      this.isSingleFlow = false;

      this.timeoutTransition();
    }, inactivityTime * 60 * 1000);
  }

  private timeoutTransition() {
    setTimeout(() => {
      if (this.startButtonClicked) {
        if (this.dataParams.Tenant === 'EPM')
          this.makeInitialGreeting();
        else
          this.translatorService.setOriginalLanguage();
      }
    }, 3000);
  }

  loadWebFirst(params: UrlParams) {
    window.SetUnityUndefined();
    this.checkBlancoxEngine();
    if (this.noAvatar) return;

    this.loadEngine();
  }

  private checkBlancoxEngine() {
    if (this.engine !== Engine.Blancox) {
      this.engineComponent.showContainerDiv();
    }
  }

  private loadEngine() {
    const engineContainer = this.engineComponent.clearComponent();
    if (this.engine === Engine.Talkinghead) {
      engineContainer.createComponent(VideoEngineComponent);
    } else if (this.engine === Engine.Three) {
      this.loadThreeEngine(engineContainer);
    } else if (this.engine === Engine.Blancox) {
      engineContainer.createComponent(BujaComponent);
    } else {
      this.loadUnityEngine();
    }
  }

  private loadUnityEngine() {
    this.downloadAvatarAssets();
    this.unityLoader = new LoadUnityService(this.bundleDownloader, this.unityControllerService);
    this.unityLoader.setIsIos(this.isIOS);
    this.unityLoader.setPersonaDigitalData(this.tenantData.data);
    this.engineComponent.subscribeLowDownloadSpeed(this.unityLoader.getLowDownloadObservable());
    this.unityLoader.loadUnityInstance();
  }

  private loadThreeEngine(engineContainer: ViewContainerRef) {
    const componentRef = engineContainer.createComponent(ThreejsAvatarsComponent);
    const threejsComponent = componentRef.instance;
    threejsComponent.setCenterAvatarObservable(this.centerAvatar.asObservable());
    threejsComponent.setBackground(this.tenantData.data.personaDigital.interfazUsuario);
    threejsComponent.elderAdultLayout = this.elderAdultLayout;
    this.downloadAvatarAssets();
  }

  async downloadAvatarAssets(): Promise<void> {
    try {
      this.bundleDownloader.getBundleLink(this.tenantData.data.personaDigital.idAvatar).subscribe({
        next: (res: any) => {
          this.handleBundleLinkResponse(res);
        }, error: (err) => {
          console.error(err);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  private handleBundleLinkResponse(res: any) {
    const responseData = JSON.parse(res).data;
    if (this.isIOS) {
      this.handleBundleForIOS(responseData);
    } else {
      this.downloadBundle(responseData);
    }
  }

  private downloadBundle(responseData: any) {
    this.bundleDownloader.downloadFile(responseData).subscribe({
      next: (res: any) => {
        this.bundleDownloader.decompressFile(res).then(() => {
          if (this.unityLoader)
            this.unityLoader.bundleDecompressedStatus = true;
        });
      }, error: (err) => {
        console.error(err);
      }
    });
  }

  private handleBundleForIOS(responseData: any) {
    // Remove from the bundle link the ".bundle.br" part
    this.unityControllerService.setIOs();
    if (this.engine == Engine.Unity)
      this.downloadUnityBundleIOS(responseData);
    else if (this.engine == Engine.Three)
      this.downloadThreeBundleIOS(responseData);
  }

  private downloadThreeBundleIOS(responseData: any) {
    const bundleLink = responseData.replace('.br', '.glb');
    this.bundleDownloader.downloadFile(bundleLink).subscribe({
      next: (res: any) => {
        this.bundleDownloader.setThreeBundle(res);
      }, error: (err) => {
        console.error(err);
      }
    });
  }

  private downloadUnityBundleIOS(responseData: any) {
    const bundleLink = responseData.replace('.bundle.br', '');
    this.bundleDownloader.setLinkBundle(bundleLink).then(() => {
      if (this.unityLoader)
        this.unityLoader.bundleDecompressedStatus = true;
    });
  }

  private checkIsIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.userAgent)
      // iPad en iOS 13+
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  private subscribeUnityControllerSpeakState(): Subscription {
    return this.unityControllerService.getSpeakState().subscribe(() => {
      if (this.predeterminedMessage) {
        this.predeterminedMessage = false;
        return;
      }
      this.resetInactivityTimer();
    });
  }

  makeInitialGreeting() {
    if (!this.avatarLoaded || !this.startButtonClicked || !this.elderAdultLayout) return;
    if (!this.isSingleFlow) {
      this.avatarInteractionsService.makeInitialGreeting(this.avatarName);
      this.predeterminedMessage = true;
    }
  }

  private subscribeAvatarLoaded(): Subscription {
    return this.unityControllerService.getAvatarState().subscribe(() => {
      this.avatarLoaded = true;
      this.makeInitialGreeting();
    });
  }

  setCenterAvatar(value: boolean) {
    this.engineComponent.centerAvatar = value;
    this.centerAvatar.next(value);
  }
}
