import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UrlParams } from 'src/app/models/parameters.model';
import { DatumButtons, StartButtons, TenantData } from 'src/app/models/tenant-data.model';
import { TenantDataService } from 'src/app/services/tenant/tenant-data.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { InterfazControles } from '../controls-section/controls-section.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { UseCasesService } from 'src/app/services/chat/use-cases.service';
import { AudioControllerService } from 'src/app/services/audio-controller.service';
import { TranslatorService } from 'src/app/services/translator.service';
import { EngineService } from 'src/app/services/engine/engine.service';

export const fadeOut = trigger('fadeOut', [
  state('visible', style({
    opacity: 1,
  })),
  state('hidden', style({
    opacity: 0,
  })),
  transition('visible => hidden', [
    animate('500ms')
  ])
])

export const fadeIn = trigger('fadeIn', [
  state('visible', style({
    opacity: 1
  })),
  state('hidden', style({
    opacity: 0
  })),
  transition('hidden => visible', [
    animate('500ms')
  ])
])


@Component({
  selector: 'app-ui-view',
  templateUrl: './ui-view.component.html',
  styleUrls: ['./ui-view.component.scss'],
  animations: [fadeOut, fadeIn]
})
export class UiViewComponent implements OnInit {
  @Input() noAvatar!: boolean;
  @Input() tenantBlancox!: boolean;
  @Input() avatarName!: string;
  @Input() initialFlow!: string;
  @Input() isGpt!: boolean;
  @Input() tenantData!: TenantData;
  @Input() startPressedObservable!: Observable<void | DatumButtons>;

  elderAdultLayout!: boolean;
  isWebChannel!: boolean;
  isSingleFlow!: boolean;
  startAppStatus = 'visible';
  uiStatus = 'hidden';

  @Input() showChat = true;

  @ViewChild('ui', { static: true }) ui!: ElementRef;
  @ViewChild('startApp') startApp!: ElementRef;

  initialButtons: StartButtons = {
    statusCode: 0,
    data: [],
    pagination: null
  }
  uiData: InterfazControles = {
    botonDescargar: false,
    botonReiniciar: false,
    botonSalir: false,
    botonVolumen: false,
    urlRedireccion: ''
  }
  private uiSubject = new Subject<InterfazControles>();
  uiObservable = this.uiSubject.asObservable();

  //Flow security
  protectedFlow!: DatumButtons;
  exitRedirectUrl!: string

  originalButtonTextArray: string[] = [];
  originalTranslatedText: string[] = [];

  constructor(
    private tenantDataService: TenantDataService,
    private audioControllerService: AudioControllerService,
    private translatorService: TranslatorService,
    private useCasesService: UseCasesService,
    private webSocketService: WebSocketService,
    private engineService: EngineService
  ) { }

  ngOnInit() {
    this.subscribeTenantData();
    this.subscribeEndConversation();
    this.subscribeLanguageState();
    this.startPressedObservable.subscribe((res) => {
      const initialButton = res ? res : this.initialButtons.data[0];
      if (this.isSingleFlow)
        this.checkFlowSecurity(initialButton);
    })
    this.useCasesService.getKeyFlowVerified()
      .subscribe(() => this.startConversation(this.protectedFlow));
  }

  private subscribeTenantData() {
    this.tenantDataService.getInitialButtonsEvent().subscribe((res: StartButtons) => {
      this.initialButtons = res;
      this.isSingleFlow = res.data.length === 1;
      res.data.forEach(button => {
        this.originalButtonTextArray.push(button.textoBoton);
        const isSpanish = this.translatorService.getCurrentLanguage() === "es";
        if (isSpanish)
          this.originalTranslatedText.push(button.textoBoton);
        else
          this.translatorService.translateText(button.textoBoton).subscribe((res: any) => {
            this.originalTranslatedText.push(res[0].translations[0].text);
          })
      })
    });
    this.tenantDataService.getDataParamsEvent().subscribe((res: UrlParams) => {
      this.isWebChannel = res.Canal === 'web' ? true : false;
      this.elderAdultLayout = res.Tenant === 'EPM'
    })
    this.tenantDataService.getDatosTenantEvent().subscribe((res: TenantData) => {
      const ui = res.data.personaDigital.interfazUsuario;
      this.uiData.botonDescargar = ui.botonDescargar;
      this.uiData.botonReiniciar = ui.botonReiniciar;
      this.uiData.botonSalir = ui.botonSalir;
      this.uiData.botonVolumen = ui.botonVolumen;
      this.uiData.urlRedireccion = ui.urlRedireccion;
      this.exitRedirectUrl = ui.urlRedireccion;
      this.uiSubject.next(this.uiData);
    });
  }

  startConversation(data: DatumButtons) {
    this.startAppStatus = 'hidden';
    this.audioControllerService.playAudio('/assets/audio/button-click.mp3');
    this.initialFlow = data.textoBoton;
    if (data.tipoNLP === "ninguno") {
      this.showChat = false;
      this.engineService.setCenterAvatar(true);
    }
    setTimeout(() => {
      this.uiStatus = 'visible';
      this.webSocketService.startConversation(data);
      if (this.startApp) this.startApp.nativeElement.style.display = 'none';
    }, 500)
  }

  private subscribeEndConversation(): Subscription {
    return this.useCasesService.getStopConversation().subscribe(() => {
      this.isSingleFlow = false;
      this.useCasesService.setMicState(-1);
      this.uiStatus = 'hidden';
      this.endConversationTransition();
    });
  }

  private endConversationTransition() {
    this.engineService.setCenterAvatar(false);
    if (this.elderAdultLayout) this.translatorService.setTargetLenguage("es");
    setTimeout(() => {
      this.showChat = true;
      this.ui.nativeElement.style.display = 'block';
      this.startAppStatus = 'visible';
      if (this.startApp) this.startApp!.nativeElement.style.display = 'flex';
    }, 500);
  }

  checkFlowSecurity(flowData: DatumButtons) {
    const isProtected = flowData.claveActiva;
    if (isProtected) {
      this.useCasesService.callFlowProtected(flowData.idFlujo);
      this.protectedFlow = flowData
    }
    else this.startConversation(flowData);
  }

  subscribeLanguageState() {
    this.translatorService.getLenguageState().subscribe((language: string) => {
      if (this.initialButtons.data.length === 0) return;
      this.changeButtonsLanguage(language === this.translatorService.getOriginalLanguage());
    });
  }

  private changeButtonsLanguage(originalNames: boolean) {
    if (originalNames) {
      for (let index = 0; index < this.originalButtonTextArray.length; index++) {
        this.initialButtons.data[index].textoBoton = this.originalTranslatedText[index];
      }
      return;
    }
    else if (this.translatorService.getOriginalButtonLanguage() === this.translatorService.getCurrentLanguage()) {
      for (let index = 0; index < this.originalButtonTextArray.length; index++) {
        this.initialButtons.data[index].textoBoton = this.originalButtonTextArray[index];
      }
    }

    this.translateInitialButtons();
  }

  private translateInitialButtons() {
    this.initialButtons.data.forEach(button => {
      this.translatorService.translateText(button.textoBoton).subscribe({
        next: (response: any) => {
          if (response && response.length > 0) {
            button.textoBoton = response[0].translations[0].text;
          }
        }, error: (err: any) => {
          console.error(err);
        }
      });
    });
  }
}
