const natiCoreServicesBaseUrl = "https://nati-panel-core-api-dllo.azurewebsites.net/api";
const natiTransversalesBaseUrl = "https://nati-transversales-dllo.azurewebsites.net/api";

export const environment = {
  // DEVELOPMENT ENVIRONMENT
  param: 'natisaas',

  // Cognitive services
  azureCognitiveServices: {
    apiKey: '3bce5e59e7674915bc50dda80b661f59',
    region: 'eastus',
    language: 'es-MX'
  },
  // Websocket connection
  webSocket: {
    url: 'wss://nati-controller-dllo.azurewebsites.net/',
    tenant: 'Innovati',
    token: '0000',
    canal: 'Web',
  },
  translateService: {
    apiKey: 'e6fdea70fde84ef090431155924eea0a',
    region: 'southcentralus',
    url: 'https://api.cognitive.microsofttranslator.com/translate',
    recognizeUrl: 'https://api.cognitive.microsofttranslator.com/detect',
    version: '3.0'
  },
  // Facturación
  urlObtenerSuscripcionesActivas: 'https://nati-facturacion-dllo.azurewebsites.net/api/suscripciones/',
  // Transversales endpoints
  transversales: {
    urlDatosTenant: `${natiCoreServicesBaseUrl}/app/ui-settings/use-case/`,
    urlBotonesInicio: `${natiCoreServicesBaseUrl}/app/start-buttons/flow/`,
    urlBotonesInicioTodos: `${natiCoreServicesBaseUrl}/app/start-buttons/use-case/`,
    urlValidarClave: `${natiCoreServicesBaseUrl}/app/validar-clave`,
    urlDescargarConversación: `${natiTransversalesBaseUrl}/conversaciones/`,
    urlLinkAvatar: `${natiTransversalesBaseUrl}/Avatar/bundle/`,
    urlEnlaces: `${natiTransversalesBaseUrl}/Enlaces`,
    urlAvatarData: `${natiTransversalesBaseUrl}/Avatar/by-id/`
  },
  // Unity Build
  build: {
    loaderUrl: `../../assets/Unity/nocompression/Build/BuildWebGL.loader.js`,
    dataUrl: "../../assets/Unity/nocompression/Build/BuildWebGL.data",
    frameworkUrl: "../../assets/Unity/nocompression/Build/BuildWebGL.framework.js",
    codeUrl: "../../assets/Unity/nocompression/Build/BuildWebGL.wasm",
    streamingAssetsUrl: "../../assets/Unity/nocompression/StreamingAssets",
  }
};
