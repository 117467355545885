import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UseCasesService } from './use-cases.service';
import { UnityControllerService } from '../unity-controller.service';
import { Conversation, ConversationElement, Detail } from '../../models/conversation.model';
import { TranslatorService } from '../translator.service';
import { Engine } from 'src/app/enums/engine';
import { randInt } from 'three/src/math/MathUtils';

@Injectable({
  providedIn: 'root'
})
export class QueueProcessorService {
  private queue: Conversation[] = [];
  private currentConversation: Conversation | null = null;
  private processing = false;
  private clearChat = new Subject<void>();
  private executeAction: any;
  private messageSubject = new Subject<any>();
  private currentMessage!: ConversationElement;
  private loadingMessage = new Subject<void>();

  // State
  private messageAddedSubject: Subject<number> = new Subject<number>();
  private processingCompleteSubject = new Subject<void>();

  constructor(private useCases: UseCasesService, private unityController: UnityControllerService, private translatorService: TranslatorService) {
    useCases.getAddNewShift().subscribe((subject: Subject<string>) => {
      const shift = "turno " + (randInt(1, 2) === 1 ? 'A-13' : 'B-41') +
        ", taquilla " + (randInt(1, 2) === 1 ? '12' : '2') +
        ", nombre " + (randInt(1, 2) === 1 ? 'Jorge Anastacio de la Rosa Bermúdez' : 'Juanita de Jesús Pérez Hernández');
      this.currentMessage.speech = shift
      this.currentMessage.text = shift
      subject.next(this.currentMessage.text);
    });
  }

  public addToQueue(conversation: Conversation) {
    this.queue.push(conversation);
    this.processQueue();
    if (conversation.conversation) this.messageAddedSubject.next(conversation.conversation?.length);
    else this.messageAddedSubject.next(0);
  }

  private async processQueue() {
    if (this.processing || this.queue.length === 0) return;

    this.processing = true;

    this.currentConversation = this.queue[0];
    while (this.currentConversation?.conversation!.length > 0) {
      const message = { ...this.currentConversation.conversation![0] };
      await this.processMessage(message);
      if (this.currentConversation != null) this.currentConversation.conversation!.shift();
    }

    this.queue.shift();
    this.processing = false;
    this.processQueue();
    this.processingCompleteSubject.next();
  }

  private async processMessage(message: ConversationElement): Promise<void> {
    this.currentMessage = message;
    try {
      if (message.detail) {
        if (!Array.isArray(message.detail)) {
          const detailArray = [message.detail];
          this.executeAction = this.useCases.separateActions(detailArray);
        }
        else {
          this.executeAction = this.useCases.separateActions(message.detail);
        }
        if (this.executeAction.onBeforeActions.length > 0) {
          this.useCases.executeOnBeforeActions(this.executeAction.onBeforeActions);
        }
        if (!Array.isArray(message.detail) && message.detail !== undefined) {
          const newDetail: Detail[] = [message.detail];
          message.detail = newDetail;
        }
        if (this.unityController.engine === Engine.Talkinghead) {
          this.loadingMessage.next();
        }
        else this.messageSubject.next(message);
      }
      const speechData = {
        speech: message.speech,
        expressions: message.expressions,
        movements: message.movements
      };
      // await this.recognizeMessageLanguage(message.speech);
      await this.unityController.sendSpeechDataToUnity(speechData);
      if (this.executeAction)
        if (this.executeAction.onAfterActions.length > 0) {
          this.useCases.executeOnAfterActions(this.executeAction.onAfterActions);
        }
    } catch (error) {
      console.log(error);
    }
  }


  public showCurrentMessage() {
    this.messageSubject.next(this.currentMessage);
  }

  public getLoadingMessage() {
    return this.loadingMessage.asObservable();
  }

  public clearMicrophoneActions() {
    this.executeAction.onAfterActions = this.executeAction.onAfterActions.filter((action: any) => {
      return action.accion !== 'enableMicrophone' && action.accion !== 'disableMicrophone';
    });
  }

  public getMessage(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  public clearQueue() {
    this.processing = false;

    this.queue = [];
    this.currentConversation = null;
    this.executeAction = {
      onBeforeActions: [],
      onAfterActions: []
    };

    this.unityController.cancelSpeech();
  }

  public skipQueue() {
    this.processing = false;
    if (this.executeAction.onAfterActions.length > 0) {
      this.useCases.executeOnAfterActions(this.executeAction.onAfterActions);
    }
    // Remove the first element of the queue
    this.currentConversation?.conversation?.shift();
    this.unityController.cancelSpeech();
    // Send all the messages to the chat
    this.currentConversation!.conversation!.forEach((message: ConversationElement) => {
      this.executeAction = this.useCases.separateActions(message.detail!);
      if (this.executeAction.onBeforeActions.length > 0) {
        this.useCases.executeOnBeforeActions(this.executeAction.onBeforeActions);
      }
      this.messageSubject.next(message);
      if (this.executeAction.onAfterActions.length > 0) {
        this.useCases.executeOnAfterActions(this.executeAction.onAfterActions);
      }
    });
    this.clearQueue();
  }

  public skipMessage(): boolean {
    this.processing = false;
    if (this.executeAction.onAfterActions.length > 0) {
      this.useCases.executeOnAfterActions(this.executeAction.onAfterActions);
    }
    this.currentConversation?.conversation?.shift();
    this.unityController.cancelSpeech();
    if (this.currentConversation!.conversation!.length > 0) {
      this.processQueue();
      return false;
    } else {
      this.clearQueue();
      return true;
    }
  }

  private async recognizeMessageLanguage(message: string): Promise<void> {
    return new Promise((resolve) => {
      this.translatorService.recognizeLanguage(message).subscribe({
        next: (response: string) => {
          if (response) {
            if (this.translatorService.getCurrentLanguage() !== response) {
              this.translatorService.setTargetLenguage(response);
            }
          }
          resolve();
        }, error: (error) => {
          console.error(error);
          resolve();
        }
      })
    })
  }

  public getClearChat() {
    return this.clearChat.asObservable();
  }

  public setClearChat() {
    this.clearChat.next();
  }

  public getMessageAddedSubject(): Observable<number> {
    return this.messageAddedSubject.asObservable();
  }

  public getProcessingCompleteSubject(): Observable<void> {
    return this.processingCompleteSubject.asObservable();
  }
}
