import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AvatarLoaderService } from '../../avatar/avatar-loader.service';
import { GazeInfo, ThreeAvatar } from 'src/app/models/three-avatar.model';
import * as THREE from 'three';
import { ThreeClockService } from '../../utils/three-clock.service';

@Injectable({
  providedIn: 'root'
})
export class GazeService {
  subscriptions$: Subscription[] = [];
  private leftEye!: THREE.Object3D;
  private rightEye!: THREE.Object3D;

  private eyesMaxXRotation = 2.5;
  private eyesRotationTime = 0.5;
  private minTimeToGaze = 2;
  private maxTimeToGaze = 3;
  private rightEyeOriginalRotation!: THREE.Euler;
  private leftEyeOriginalRotation!: THREE.Euler;
  private waitTimeToGaze = 0;
  private startTimeToGaze = 0;
  private canRandomlyGaze = false;
  private eyesRotating = false;
  private isWaitingToGaze = false;
  private startTimeToRotate = 0;

  private rotateEndTime = 0;
  private rightTargetRotation: THREE.Quaternion = new THREE.Quaternion();
  private leftTargetRotation: THREE.Quaternion = new THREE.Quaternion();

  constructor(private avatarLoaderService: AvatarLoaderService, private clockService: ThreeClockService) {
    this.initServices();
  }

  private initServices() {
    this.subscriptions$.push(
      this.avatarLoaderService.getAvatarInfo().subscribe((avatarInfo: ThreeAvatar) => {
        this.start(avatarInfo.gazeInfo);
      })
    );
  }

  private start(gazeInfo: GazeInfo){
    this.rightEye = gazeInfo.rightEye;
    this.leftEye = gazeInfo.leftEye;
    this.rightEyeOriginalRotation = this.rightEye.rotation.clone();
    this.leftEyeOriginalRotation = this.leftEye.rotation.clone();
    this.canRandomlyGaze = true;
    this.isWaitingToGaze = true;
  }

  public update(){
    //this.headMovement.update();
    this.waitToRotateEyes();
    this.executeRotation();
  }

  private waitToRotateEyes(){
    if(!this.isWaitingToGaze || !this.canRandomlyGaze) return;
    if(this.clockService.clock.getElapsedTime() > this.startTimeToGaze + this.waitTimeToGaze){
      this.rotateEyes();
      this.isWaitingToGaze = false;
    }
  }

  private rotateEyes(){
    if(!this.canRandomlyGaze) return;
    const xRot = THREE.MathUtils.randFloat(-THREE.MathUtils.degToRad(this.eyesMaxXRotation), THREE.MathUtils.degToRad(this.eyesMaxXRotation));
    const randomRotationRight = new THREE.Euler(xRot + this.rightEyeOriginalRotation.x, this.rightEyeOriginalRotation.y, this.rightEyeOriginalRotation.z);
    const randomRotationLeft = new THREE.Euler(xRot + this.leftEyeOriginalRotation.x, this.leftEyeOriginalRotation.y, this.leftEyeOriginalRotation.z);
    this.rightTargetRotation.setFromEuler(randomRotationRight);
    this.leftTargetRotation.setFromEuler(randomRotationLeft);
    this.startTimeToRotate = this.clockService.clock.getElapsedTime();
    this.eyesRotating = true;
    this.rotateEndTime = this.startTimeToRotate + this.eyesRotationTime;
  }

  private executeRotation(){
    if(!this.eyesRotating) return;
    const elapsedTime = this.clockService.clock.getElapsedTime();
    if(elapsedTime < this.rotateEndTime){
      this.rightEye.quaternion.slerp(this.rightTargetRotation, 1 - ((this.rotateEndTime - elapsedTime) / this.eyesRotationTime));
      this.leftEye.quaternion.slerp(this.leftTargetRotation, 1 - ((this.rotateEndTime - elapsedTime) / this.eyesRotationTime))
    }
    else{
      this.eyesRotating = false;
      this.prepareNextGaze();
    }
  }

  private prepareNextGaze(){
    this.waitTimeToGaze = Math.random() * (this.maxTimeToGaze - this.minTimeToGaze) + this.minTimeToGaze;
    this.isWaitingToGaze = true;
    this.startTimeToGaze = this.clockService.clock.getElapsedTime();
  }

  public enableRandomGaze(enable: boolean){
    if(enable){
      this.canRandomlyGaze = true;
      this.waitTimeToGaze = 0;
      this.startTimeToGaze = this.clockService.clock.getElapsedTime();
    }
    else{
      this.canRandomlyGaze = false;
      this.startTimeToRotate = this.clockService.clock.getElapsedTime();
      this.eyesRotating = true;
      this.rotateEndTime = this.startTimeToRotate + this.eyesRotationTime;
      this.rightTargetRotation = new THREE.Quaternion().setFromEuler(this.rightEyeOriginalRotation);
      this.leftTargetRotation = new THREE.Quaternion().setFromEuler(this.leftEyeOriginalRotation);
    }
  }
}
