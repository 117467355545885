export const environment = {
  // DEVELOPMENT ENVIRONMENT
  param: 'natisaas',

  // Cognitive services
  azureCognitiveServices: {
    apiKey: '3bce5e59e7674915bc50dda80b661f59',
    region: 'eastus',
    language: 'es-MX'
  },
  // Websocket connection
  webSocket: {
    url: 'wss://nati-controller-dllo.azurewebsites.net/',
    tenant: 'Innovati',
    token: '0000',
    canal: 'Web',
  },
  translateService: {
    apiKey: 'e6fdea70fde84ef090431155924eea0a',
    region: 'southcentralus',
    url: 'https://api.cognitive.microsofttranslator.com/translate',
    recognizeUrl: 'https://api.cognitive.microsofttranslator.com/detect',
    version: '3.0'
  },
  // Facturación
  urlObtenerSuscripcionesActivas: 'https://nati-facturacion-dllo.azurewebsites.net/api/suscripciones/',
  // Transversales endpoints
  transversales: {
    urlDatosTenant: 'https://nati-panel-core-api-dllo.azurewebsites.net/api/app/ui-settings/use-case/',
    urlBotonesInicio: 'https://nati-panel-core-api-dllo.azurewebsites.net/api/app/start-buttons/flow/',
    urlBotonesInicioTodos: 'https://nati-panel-core-api-dllo.azurewebsites.net/api/app/start-buttons/use-case/',
    urlValidarClave:"https://nati-panel-core-api-dllo.azurewebsites.net/api/app/validar-clave",
    urlDescargarConversación: 'https://nati-transversales-dllo.azurewebsites.net/api/conversaciones/',
    urlLinkAvatar: 'https://nati-transversales-dllo.azurewebsites.net/api/Avatar/bundle/',
    urlEnlaces: 'https://nati-transversales-dllo.azurewebsites.net/api/Enlaces',
    urlAvatarData: 'https://nati-transversales-dllo.azurewebsites.net/api/Avatar/by-id/'
  },
  // Unity Build
  build: {
    loaderUrl: `../../assets/Unity/compression/Build/BuildWebGL.loader.js`,
    dataUrl: "../../assets/Unity/compression/Build/BuildWebGL.data.br",
    frameworkUrl: "../../assets/Unity/compression/Build/BuildWebGL.framework.js.br",
    codeUrl: "../../assets/Unity/compression/Build/BuildWebGL.wasm.br",
    streamingAssetsUrl: "../../assets/Unity/compression/StreamingAssets",
  }
};
