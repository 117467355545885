import { Injectable, OnInit } from '@angular/core';
import * as THREE from 'three';
import { ThreeClockService } from '../utils/three-clock.service';
import { Subscription } from 'rxjs';
import { AvatarLoaderService } from '../avatar/avatar-loader.service';
import { ThreeAvatar } from 'src/app/models/three-avatar.model';
import { AzureLipsyncService } from '../lipsync/azure-lipsync.service';
import { UnityControllerService } from 'src/app/services/unity-controller.service';

@Injectable({
  providedIn: 'root'
})
export class AnimationSystemService {
  subscriptions$: Subscription[] = [];

  private mixer: THREE.AnimationMixer;
  private idleClips: THREE.AnimationAction[];
  private talkClips: THREE.AnimationAction[];
  private welcomeClips: THREE.AnimationAction[];
  private defaultClip!: THREE.AnimationAction;
  private pointClips: THREE.AnimationAction[];
  private finishCycle!: any;
  private lastClip!: THREE.AnimationAction;
  private lastTalkClip: THREE.AnimationAction | null = null;
  private currentAnimationDuration!: number;
  private isExecutingTalkAnimation = false;
  private currentAudioDuration!: number;
  private animationTimeCount = 0;

  constructor(private clockService: ThreeClockService, private avatarLoaderService: AvatarLoaderService,
    private lipsyncService: AzureLipsyncService, private unityControllerService: UnityControllerService) {
    this.idleClips = [];
    this.talkClips = [];
    this.welcomeClips = [];
    this.pointClips = [];
    this.mixer = new THREE.AnimationMixer(new THREE.Mesh);
    this.initServices();

  }

  initServices() {
    this.subscriptions$.push(
      this.avatarLoaderService.getAvatarInfo().subscribe((avatarInfo: ThreeAvatar) => {
        this.setAnimationMixer(avatarInfo.object as THREE.Mesh, avatarInfo.animations);
      }),
      this.lipsyncService.getMovementState().subscribe(movements => this.playAnimation(movements)),
      this.lipsyncService.getLipsyncState().subscribe((talking: boolean) => {
        if (!talking) this.playAnimation('default');
      }),
      this.unityControllerService.getSpeechBlendData().subscribe((speechData: any) => {
        this.currentAudioDuration = speechData.audioDuration / 10000000;
      })
    );
  }

  public setAnimationMixer(mesh: THREE.Mesh, animations: THREE.AnimationClip[]) {
    this.mixer = new THREE.AnimationMixer(mesh);
    this.loadAnimations(animations);
  }

  public update() {
    this.mixer.update(this.clockService.clock.getDelta());
    this.checkAnimationState();
  }

  private loadAnimations(animations: THREE.AnimationClip[]) {
    animations.forEach(animation => {
      this.fixAnimations(animation);
      if (animation.name.toLowerCase().includes("idling")) {
        this.defaultClip = this.mixer.clipAction(animation);
      }
      else
        if (animation.name.toLowerCase().includes("idle")) {
          this.idleClips.push(this.mixer.clipAction(animation));
        }
        else if (animation.name.toLowerCase().includes("talk")) {
          this.talkClips.push(this.mixer.clipAction(animation));
        }
        else if (animation.name.toLowerCase().includes('greeting')) {
          this.welcomeClips.push(this.mixer.clipAction(animation));
        }
        else if(animation.name.toLocaleLowerCase().includes('point')) {
          this.pointClips.push(this.mixer.clipAction(animation));
        }
    });
    if (this.defaultClip) {
      this.mixer.timeScale = 0.3;
      this.finishCycle = this.onCycleFinished.bind(this);
      this.mixer.addEventListener('loop', this.finishCycle);
      this.defaultClip.play();
      this.lastClip = this.defaultClip;
      this.currentAnimationDuration = this.lastClip.getClip().duration;
    }
  }

  private fixAnimations(animation: THREE.AnimationClip) {
    animation.tracks.forEach(track => {
      if (track.name.toLowerCase().includes("jawroot") || track.name.toLowerCase().includes("base_head") || track.name.toLowerCase().includes("eye")) {
        const index = animation.tracks.indexOf(track);
        animation.tracks.splice(index, 3);
      }
    })
  }

  public playAnimation(animation: string) {
    if (animation.toLowerCase().includes("talk")) {
      this.setAnimation(this.talkClips, 0.7);
    }
    else if (animation.toLowerCase().includes("idle")) {
      this.setAnimation(this.idleClips, 0.7);
    }
    else if (animation.toLowerCase().includes("greeting") || animation.toLowerCase().includes("goodbye")) {
      this.setAnimation(this.welcomeClips, 0.7);
    }
    else if (animation.toLocaleLowerCase().includes("point")) {
      this.setAnimation(this.pointClips, 0.7);
    }
    else {
      this.setDefaultAnimation();
    }
  }

  private setDefaultAnimation() {
    if (this.lastClip === this.defaultClip) return;
    this.animationTimeCount = 0;
    this.mixer.timeScale = 0.3;
    this.defaultClip.reset();
    this.defaultClip.fadeIn(0.25).play();
    this.lastClip.fadeOut(0.25);
    this.lastClip = this.defaultClip;
    this.currentAnimationDuration = this.lastClip.getClip().duration;
    this.isExecutingTalkAnimation = false;
  }

  private setAnimation(clips: THREE.AnimationAction[], timeScale: number, fadeDuration = 0.75) {
    let index = Math.floor(Math.random() * clips.length);
    this.mixer.timeScale = timeScale;
    if (!this.lastClip) {
      clips[index].play();
      this.defaultClip.crossFadeTo(clips[index], 0.5, true);
      this.lastClip = clips[index];
      return;
    }
    if (this.lastClip == clips[index] || this.lastTalkClip == clips[index]) {
      index = index == clips.length - 1 ? 0 : index + 1;
    }
    clips[index].timeScale = timeScale;
    clips[index].reset();
    this.lastClip.crossFadeTo(clips[index], fadeDuration, false).play();
    this.lastClip = clips[index];
    this.currentAnimationDuration = this.lastClip.getClip().duration;
    if (clips === this.talkClips) {
      this.lastTalkClip = clips[index];
      this.isExecutingTalkAnimation = true;
      this.animationTimeCount += this.currentAnimationDuration;
    }
    else {
      this.isExecutingTalkAnimation = false;
    }
  }

  private onCycleFinished() {
    if (this.lastClip === this.defaultClip) {
      this.setAnimation(this.idleClips, 0.7);
    }
    else if (!this.talkClips.includes(this.lastClip)) {
      this.setDefaultAnimation();
    }
  }

  private checkAnimationState() {
    if (!this.lastTalkClip?.time) return;
    if (this.isExecutingTalkAnimation && this.lastTalkClip?.time / this.currentAnimationDuration > 0.7) {
      if (this.currentAudioDuration - this.animationTimeCount > 9) {
        this.setAnimation(this.talkClips, 0.7, this.currentAnimationDuration - this.lastTalkClip?.time);
      }
      else {
        this.setDefaultAnimation();
      }
    }
  }
}
