import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { TranslatorService } from 'src/app/services/translator.service';

interface Lang {
  name: string,
  code: string
}

@Component({
  selector: 'app-switchlang',
  templateUrl: './switchlang.component.html',
  styleUrls: ['./switchlang.component.scss']
})
export class SwitchlangComponent {
  scrollFinished = false;
  scrollTimeout!: NodeJS.Timeout;
  @Input() elderLayout!: boolean;

  @ViewChild('dropdownScroll') dropdownScroll!: ElementRef;
  languages: Lang[];
  selectedLanguage?: Lang;
  showLanguages = false;
  langButtonPressed = false;

  constructor(private translatorService: TranslatorService) {
    this.languages = [
      { name: 'Español', code: 'es' },
      { name: 'English', code: 'en' },
      { name: 'Français', code: 'fr' },
      { name: 'Português', code: 'pt' },
      { name: 'Deutsch', code: 'de' },
      { name: 'Italiano', code: 'it' },
      { name: '中国', code: 'zh-Hans' },

    ];
    translatorService.getLenguageState().subscribe((code: string) => {
      if (code === this.selectedLanguage?.code) return;
      this.selectedLanguage = this.languages.find(l => l.code === code);
    })
  }

  switchLang() {
    try {
      this.translatorService.setTargetLenguage(this.selectedLanguage!.code);
    } catch (error) {
      console.error(error);
    }
  }

  toggleLanguages() {
    this.showLanguages = !this.showLanguages;
    this.langButtonPressed = true;
    if (this.showLanguages) {
      setTimeout(() => {
        const optionHeight = window.innerHeight * 0.1;
        const index = this.languages.findIndex(x => x === this.selectedLanguage);
        const scrollDiv = this.dropdownScroll.nativeElement;
        scrollDiv.scrollTo({ top: optionHeight * index });
        this.langButtonPressed = false;
      }, 100)
    }
  }

  onScroll() {
    this.scrollFinished = false;
    clearTimeout(this.scrollTimeout);

    this.scrollTimeout = setTimeout(() => {
      this.scrollFinished = true;
      this.updateLanguage();
    }, 200);
  }

  updateLanguage() {
    const optionHeight = window.innerHeight * 0.1;
    const scrollDiv = this.dropdownScroll.nativeElement;
    const langIdex = Math.round(scrollDiv.scrollTop / optionHeight);
    this.selectedLanguage = this.languages[langIdex];
    this.switchLang();
  }
  scroll(up: boolean) {
    const direction = up ? 1 : -1;
    const optionHeight = window.innerHeight * 0.1;
    const scrollDiv = this.dropdownScroll.nativeElement;
    scrollDiv.scrollTo({ top: scrollDiv.scrollTop - optionHeight * direction, behavior: 'smooth' });
    this.updateLanguage();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.dropdownScroll === undefined || this.langButtonPressed) return;
    const clickedInside = this.dropdownScroll.nativeElement.contains(event.target);
    if (!clickedInside)
      this.toggleLanguages();
  }
}
