<div [ngClass]="elderLayout ? 'switchlang-elder' : 'switchlang'">
  <button class="lang-button" (click)="toggleLanguages()">
    <svg class="lang-icon" width="73" height="77" viewBox="0 0 73 77" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.8125" y="1.5" width="69" height="73.2985" rx="22.5" stroke="currentcolor" stroke-width="3" />
      <path
        d="M10 38.5C10 50.9267 21.5383 61 35.7723 61C50.0064 61 61.5446 50.9267 61.5446 38.5C61.5446 26.0733 50.0064 16 35.7723 16C21.5383 16 10 26.0733 10 38.5Z"
        stroke="currentcolor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M38.3431 16.1094C38.3431 16.1094 46.0748 24.9969 46.0748 38.4969C46.0748 51.9969 38.3431 60.8844 38.3431 60.8844M33.1886 60.8844C33.1886 60.8844 25.4569 51.9969 25.4569 38.4969C25.4569 24.9969 33.1886 16.1094 33.1886 16.1094M11.6172 46.3719H59.9145M11.6172 30.6219H59.9145"
        stroke="currentcolor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </button>

  <div class="language-dropdown" *ngIf="showLanguages">
    <span class="dropdown-tittle lang-span">
      {{'ui.switchLang' | translate}}
    </span>
    <div class="body-scroll" #dropdownScroll (scroll)="onScroll()">
      <div class="dropdown-body">
        <span class="lang-span" *ngFor="let language of languages">{{language.name}}</span>
      </div>
      <div class="body-up-panel" (click)="scroll(true)"></div>
      <div class="body-down-panel" (click)="scroll(false)"></div>
    </div>
  </div>
  <!-- <p-dropdown [options]="languages" [(ngModel)]="selectedLanguage" optionLabel="name" (onChange)="switchLang()">
    <ng-template let-country pTemplate="selectedItem">
      <div class="text-start" *ngIf="selectedLanguage">{{selectedLanguage.name}}</div>
    </ng-template>
    <ng-template let-country pTemplate="item">
      <div class="text-start">{{country.name}}</div>
    </ng-template>
  </p-dropdown> -->
</div>