<div #containerNumericKeyboard id="containerNumericKeyboard" class="numeric-key-container">
    <div class="numeric_keyboard">
        <div class="input-message" *ngIf="showInputFeedback">
            <input type="text" id="chat-input" class="input" #inputFeedback maxlength="512" [disabled]="true">
            <hr class="separation-line">
        </div>
        <div class="numeric_keyboard__keys">
            <button id="key7" type="button" class="numeric_keyboard__key" (click)="type(7)">7</button>
            <button id="key8" type="button" class="numeric_keyboard__key" (click)="type(8)">8</button>
            <button id="key9" type="button" class="numeric_keyboard__key" (click)="type(9)">9</button>
            <button id="key4" type="button" class="numeric_keyboard__key" (click)="type(4)">4</button>
            <button id="key5" type="button" class="numeric_keyboard__key" (click)="type(5)">5</button>
            <button id="key6" type="button" class="numeric_keyboard__key" (click)="type(6)">6</button>
            <button id="key1" type="button" class="numeric_keyboard__key" (click)="type(1)">1</button>
            <button id="key2" type="button" class="numeric_keyboard__key" (click)="type(2)">2</button>
            <button id="key3" type="button" class="numeric_keyboard__key" (click)="type(3)">3</button>
            <button id="key0" type="button" class="numeric_keyboard__key" (click)="type(0)">0</button>
            <button id="erase" type="button" class="numeric_keyboard__key action_key" (click)="erase()">Borrar</button>
            <button id="send" type="button" class="numeric_keyboard__key action_key" (click)="send()">
                <span class="send">Enviar</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="icons-ui" viewBox="0 0 24 24">
                    <path fill="var(--dynamic-button-text-color)"
                        d="m21.426 11.095-17-8A.999.999 0 0 0 3.03 4.242L4.969 12 3.03 19.758a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81zM5.481 18.197l.839-3.357L12 12 6.32 9.16l-.839-3.357L18.651 12l-13.17 6.197z" />
                </svg>
            </button>
        </div>
    </div>
</div>