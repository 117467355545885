<div class="container-logo" #logoTenant>
    <img [src]="logoTenantUrl" alt="logo-tenant" class="logo-tenant" *ngIf="logoTenantUrl">
</div>
<!-- Vista inicial de la aplicación -->
<div #initialViewApp [@fadeIn]="isVisible" [@fadeOut]="isVisible" class="initial-view-app">
    <div class="initial-view-app">
        <div class="container-avatar-preview" *ngIf="!noAvatar">
            <img [src]="previewAvatarImgUrl" class="avatar-preview-img" alt="Avatar" *ngIf="previewAvatarImgUrl">
        </div>
        <app-vertical-ui-background></app-vertical-ui-background>

        <app-loading-chat *ngIf="isLoading"></app-loading-chat>
        <div class="container-initial-button">
            <button #startButton *ngIf="!isLoading" type="button" class="btn initial-view-app-button shadow-sm"
                (click)="skipInitialView()">
                {{'ui.initialApp.continue' | translate}}
            </button>
        </div>
    </div>
</div>
<div #imageBg class="image-bg" [@fadeIn]="isVisible" [@fadeOut]="isVisible"></div>
<!-- Vista inicial de la aplicación -->