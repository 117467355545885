import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UseCasesService } from 'src/app/services/chat/use-cases.service';

export interface WarningMessage {
  title: string;
  message: string;
}


@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit {
  @ViewChild('warnModal', { static: true }) warnModal!: ElementRef;
  warningTitle!: string;
  warningMessage!: string;
  modal!: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private useCasesService: UseCasesService,
  ) {
  }

  ngOnInit(): void {
    this.useCasesService.getShowWarningModal().subscribe((message: WarningMessage) => {
      console.log('showing warning modal');
      this.warningTitle = message.title;
      this.warningMessage = message.message;
      this.modal = this.modalService.open(this.warnModal, { centered: true, backdrop: "static", size: "lg" });
    })
  }

  continue() {
    throw new Error('Method not implemented.');
  }
  cancel() {
    this.modal.close();
  }

}
