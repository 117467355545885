import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Engine } from 'src/app/enums/engine';
import { BundleDownloader } from 'src/app/services/bundle-downloader.service';
import { UnityControllerService } from 'src/app/services/unity-controller.service';
import { TenantDataService } from 'src/app/services/tenant/tenant-data.service';
import { AvatarData } from 'src/app/models/avatar-data.model';
import { AudioControllerService } from 'src/app/services/audio-controller.service';
import { HttpClient } from '@angular/common/http';
import { QueueProcessorService } from 'src/app/services/chat/queue-processor.service';


@Component({
  selector: 'app-video-engine',
  templateUrl: './video-engine.component.html',
  styleUrls: ['./video-engine.component.scss']
})
export class VideoEngineComponent implements OnInit {
  @ViewChild('videoAvatar') videoAvatar!: ElementRef

  // natiIdleRemoteURL = 'https://natistoragedllo.blob.core.windows.net/bundle/nati.mp4'
  natiVideoBlobURL!: string
  natiVideoIdleURL!: string
  aspectRatio = window.innerWidth / window.innerHeight
  avatarId!: string
  bundleUrl!: string;
  rtthURL = "https://e44f-2800-e2-8e80-15e-00-6.ngrok-free.app"
  rtthEndPoint = "/talkinghead/realtime"
  constructor(
    private bundleDownloader: BundleDownloader,
    private tenantDataService: TenantDataService,
    private unityControllerService: UnityControllerService,
    private queueProcessorService: QueueProcessorService,
    private audioControllerService: AudioControllerService,
    private http: HttpClient
  ) { }
  ngOnInit(): void {
    // this.subscribeDownloadIdleVideo();
    this.subscribeAvatarSpeech();
    this.subscribeAvatarId();
    this.unityControllerService.engine = Engine.Talkinghead;
  }

  private subscribeAvatarId() {
    this.tenantDataService.getAvatarDataLocal().subscribe({
      next: (res: AvatarData) => {
        this.avatarId = res.data.idAvatar;
        this.bundleDownloader.getBundleLink(this.avatarId).subscribe({
          next: (res: any) => {
            this.bundleUrl = JSON.parse(res).data
            this.natiVideoIdleURL = this.bundleUrl
            this.natiVideoBlobURL = this.natiVideoIdleURL
            console.log(this.natiVideoIdleURL)
          }
        })
      }
    })
  }

  private subscribeAvatarSpeech() {

    this.unityControllerService.getAudioBlob().subscribe({
      next: (audioBlob: Blob) => {
        this.sendVideoRequest(audioBlob);
      }
    })
  }

  private sendVideoRequest(audioBlob: Blob) {
    const createTalkingURL = `${this.rtthURL}${this.rtthEndPoint}/${this.avatarId}`;
    const audioFile = new File([audioBlob], 'audio.wav', { type: 'audio/wav' });
    const body = new FormData();
    body.append('video_idle_url', this.bundleUrl);
    body.append('audio', audioFile);

    console.log("Body:", body);
    this.http.post(createTalkingURL, body, { responseType: 'blob' }).subscribe({
      next: (res) => {
        this.handleResponse(res);
        this.queueProcessorService.showCurrentMessage();
      },
      error: (e) => {
        console.log("Error RTTH", e);
        setTimeout(() => {
          this.queueProcessorService.showCurrentMessage();
          this.audioControllerService.playAudioFromBlob(audioBlob);
        }, 3000);
      }
    });
  }

  private handleResponse(res: Blob) {
    console.log("Res RTTH", res);
    const videoBlob = new Blob([res], { type: 'video/mp4' });
    this.natiVideoBlobURL = URL.createObjectURL(videoBlob);
  }

  // private subscribeDownloadIdleVideo() {
  //   this.bundleDownloader.downloadFile(this.natiVideoIdleURL).subscribe({
  //     next: (res: any) => {
  //       this.bundleDownloader.setBundle(res).then(() => {
  //         this.natiVideoBlobURL = this.bundleDownloader.getUrl();
  //         this.natiVideoIdleURL = this.natiVideoBlobURL
  //       });
  //     },
  //     error: (e) => console.log("No se ha descargado el video de nati", e)
  //   });
  // }

  checkSetIdle() {
    URL.revokeObjectURL(this.natiVideoBlobURL)
    this.natiVideoBlobURL = this.natiVideoIdleURL
    this.videoAvatar.nativeElement.play()
  }
}
