import { Injectable } from '@angular/core';
import { ThreeClockService } from '../../utils/three-clock.service';
import { AvatarExpressionsService } from './avatar-expressions.service';
import { AvatarLoaderService } from '../../avatar/avatar-loader.service';
import { ThreeAvatar } from 'src/app/models/three-avatar.model';
import { Subscription } from 'rxjs';
import { AzureLipsyncService } from '../../lipsync/azure-lipsync.service';
import { BundleDownloader } from 'src/app/services/bundle-downloader.service';

@Injectable({
  providedIn: 'root'
})
export class ExpressionSystemService {
  subscriptions$: Subscription[] = [];
  private expressionsLength!: number;
  private nonSpeakExpressionsLength!: number;
  private durationMin = 4;
  private durationMax = 8;
  private lastExpressionStart!: number;
  private currentDuration = 4;
  private isOnBase = false;
  private expressionsLoaded = false;
  private isTalking = false;
  private avatarName!: string;
  private avatarReady = false;

  constructor(private threeClockService: ThreeClockService, private avatarExpressions: AvatarExpressionsService,
    private avatarLoaderService: AvatarLoaderService, private azureLipsyncService: AzureLipsyncService,
    private bundleDownloaderService: BundleDownloader) {
    this.initServices();
  }

  private initServices() {
    this.subscriptions$.push(
      this.avatarLoaderService.getAvatarInfo().subscribe((avatarInfo: ThreeAvatar) => {
        this.setUp(avatarInfo.skinnedMesh);
        this.avatarReady = true;
      }),
      this.azureLipsyncService.getLipsyncState().subscribe((talking: boolean) => {
        if (this.isTalking === talking) return;
        this.isTalking = talking;
        if (!talking) {
          this.cleanExpression();
          this.isOnBase = true;
        }
      }),
      this.bundleDownloaderService.getAvatarname().subscribe((name: string) => {
        this.avatarName = name;
      })
    );
    window.addEventListener('focus', () => {
      if (this.avatarReady) {
        this.setFirstExpression();
        this.isOnBase = true;
      }
    });
  }
  public updateExpressions() {
    if (!this.expressionsLoaded || this.isTalking) return;
    this.avatarExpressions.update(this.threeClockService.clock.getElapsedTime());
    if ((this.threeClockService.clock.getElapsedTime() - this.lastExpressionStart) > this.currentDuration) {
      if (this.isOnBase) {
        this.setRandomExpression();
        this.isOnBase = false;
      }
      else {
        this.setDefaultExpression();
        this.isOnBase = true;
      }
    }
  }

  public async setUp(skinnedMesh: THREE.SkinnedMesh) {
    this.lastExpressionStart = this.threeClockService.clock.getElapsedTime();
    const expressionLists = await this.avatarExpressions.setExpressions(`../../../../../../assets/threejs/models/expressions/${this.avatarName}_expressions.json`, skinnedMesh)
    this.expressionsLength = expressionLists.expressionsLength;
    this.nonSpeakExpressionsLength = expressionLists.nonSmileExpressionsLength;
    this.expressionsLoaded = true;
  }

  private setRandomExpression() {
    let expression = 0;
    this.currentDuration = Math.floor(Math.random() * (this.durationMax - this.durationMin + 1) + this.durationMin);
    if(this.isTalking){
      expression = Math.floor(Math.random() * this.nonSpeakExpressionsLength);
      this.avatarExpressions.setNonSmileExpression(expression);
    }
    else{
      expression = Math.floor(Math.random() * this.expressionsLength);
      this.avatarExpressions.setExpression(expression);
    }
    this.startTransition();
  }

  private setDefaultExpression() {
    this.currentDuration = Math.floor(Math.random() * (this.durationMax - this.durationMin + 1) + this.durationMin);
    this.avatarExpressions.setBaseExpression();
    this.startTransition();
  }

  private cleanExpression(){
    this.currentDuration = Math.floor(Math.random() * (this.durationMax - this.durationMin + 1) + this.durationMin);
    this.avatarExpressions.cleanExpression();
    this.startTransition(0.5);
  }

  private setFirstExpression() {
    this.currentDuration = Math.floor(Math.random() * (this.durationMax - this.durationMin + 1) + this.durationMin);
    this.avatarExpressions.cleanExpression();
    this.startTransition(0);
  }

  private startTransition(transitionTime = 0.2){
    this.avatarExpressions.startTransition(this.threeClockService.clock.getElapsedTime(), transitionTime);
    this.lastExpressionStart = this.threeClockService.clock.getElapsedTime();
  }
}
