import { Injectable } from '@angular/core';
import * as THREE from 'three';

@Injectable({
  providedIn: 'root'
})
export class AvatarExpressionsService {
  private blendShapes!: any;
  private blendShapesInfluences!: any;
  private expressions: any = {};
  private nonSmileExpressions: any = {};
  private baseExpression: any = {};
  private transitionTime = 0.2;
  private lastExpression: any = {};
  private inTransition = false;
  private startTime = 0;
  private endTime = 0;
  private startMorph: any = {};
  private endMorph: any = {};  

  public async setExpressions(expressionPath: string, skinnedMesh: THREE.SkinnedMesh) {
    this.blendShapes = skinnedMesh.morphTargetDictionary;
    this.blendShapesInfluences = skinnedMesh.morphTargetInfluences;
    await this.readJson(expressionPath);
    return {expressionsLength: Object.keys(this.expressions).length, nonSmileExpressionsLength: Object.keys(this.nonSmileExpressions).length};
  }

  private async readJson(expressionPath: string) {
    try {
      const response = await fetch(expressionPath);
      const data = await response.json();
      for (const expression in data.expressions) {
        if (expression === "base") {
          for (const morphTarget in data.expressions[expression]) {
            this.baseExpression[this.blendShapes[morphTarget]] = data.expressions.base[morphTarget] * data.expressions.base.intensity;
          }
        }
        else {
          this.expressions[expression] = { expression };
        }
        this.baseExpression.intensity = data.expressions.base.intensity;
      }
      for (const expression in this.expressions) {
        for (const morphTarget in data.expressions[expression]) {
          this.expressions[expression][this.blendShapes[morphTarget]] = data.expressions[expression][morphTarget] * data.expressions[expression].intensity;
        }
        for (const morphTarget in this.baseExpression) {
          if (!this.expressions[expression][morphTarget]) {
            this.expressions[expression][morphTarget] = this.baseExpression[morphTarget] * data.expressions.base.intensity;
          }
        }
        this.expressions[expression].intensity = data.expressions[expression].intensity;
        if(!expression.toLowerCase().includes("smile")){
          this.nonSmileExpressions[expression] = this.expressions[expression];
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public setExpression(target: number) {
    const newExpression = Object.keys(this.expressions)[target];
    this.startMorph = Object.assign({}, this.lastExpression);
    for (const morphTarget in this.expressions[newExpression]) {
      if (!this.startMorph[morphTarget]) {
        this.startMorph[morphTarget] = 0;
      }
    }
    this.endMorph = Object.assign({}, this.expressions[newExpression]);
    this.lastExpression = this.expressions[newExpression];
  }

  public setNonSmileExpression(target: number){
    const newExpression = Object.keys(this.nonSmileExpressions)[target];
    this.startMorph = Object.assign({}, this.lastExpression);
    for (const morphTarget in this.nonSmileExpressions[newExpression]) {
      if (!this.startMorph[morphTarget]) {
        this.startMorph[morphTarget] = 0;
      }
    }
    this.endMorph = Object.assign({}, this.nonSmileExpressions[newExpression]);
    this.lastExpression = this.nonSmileExpressions[newExpression];
  }

  public setBaseExpression() {
    if (Object.keys(this.lastExpression).length == 0) {
      for (const morphTarget in this.baseExpression) {
        this.startMorph[morphTarget] = 0;
      }
    }
    else {
      this.startMorph = Object.assign({}, this.lastExpression);
    }
    this.endMorph = Object.assign({}, this.baseExpression);
    for (const morphTarget in this.lastExpression) {
      if (!this.endMorph[morphTarget]) {
        this.endMorph[morphTarget] = 0;
      }
    }
    this.lastExpression = this.baseExpression;
  }

  public cleanExpression(){
    this.lastExpression = {};
    this.startMorph = {};
    for (const morphTarget in this.blendShapes) {
      if(!this.baseExpression[this.blendShapes[morphTarget]]){
        this.blendShapesInfluences[this.blendShapes[morphTarget]] = 0;
      }
    }
    this.setBaseExpression();
  }

  public update(currentTime: number) {
    if (this.inTransition) {
      this.makeTransition(currentTime);
    }
  }

  public startTransition(startTime: number, transtionTime: number = this.transitionTime) {
    if (Object.keys(this.startMorph).length != Object.keys(this.endMorph).length) {
      console.error("start morph targets must be equal to end morph targets");
      return;
    }
    this.startTime = startTime;
    this.endTime = startTime + transtionTime;
    this.inTransition = true;
  }

  private makeTransition(currentTime: number) {
    if (this.endTime === this.startTime) {
      this.makeInstantTransition();
      return;
    }
    const percent = (currentTime - this.startTime) / (this.endTime - this.startTime);
    for (const morphTarget in this.endMorph) {
      this.blendShapesInfluences[morphTarget] = this.startMorph[morphTarget] + (this.endMorph[morphTarget] - this.startMorph[morphTarget]) * percent;
    }
    if (currentTime >= this.endTime) {
      this.inTransition = false;
    }
  }

  private makeInstantTransition() {
    for (const morphTarget in this.endMorph) {
      this.blendShapesInfluences[morphTarget] = this.endMorph[morphTarget];
    }
    for (const blendshape in this.blendShapes) {
      if(!this.endMorph[this.blendShapes[blendshape]]) this.blendShapesInfluences[this.blendShapes[blendshape]] = 0;
    }
    this.inTransition = false;
  }
}
