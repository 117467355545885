import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TenantData, StartButtons } from 'src/app/models/tenant-data.model';
import { UrlParams } from '../../models/parameters.model';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { AvatarData } from '../../models/avatar-data.model';

@Injectable({
  providedIn: 'root'
})
export class TenantDataService {
  private urlDatosTenant = environment.transversales.urlDatosTenant;
  private urlBotonesInicio = environment.transversales.urlBotonesInicio;
  private urlBotonesInicioTodos = environment.transversales.urlBotonesInicioTodos;
  private urlDescargarConversación = environment.transversales.urlDescargarConversación;
  private urlAvatarData = environment.transversales.urlAvatarData;

  private dataParams = new Subject<UrlParams>();
  private dataTenant = new Subject<TenantData>();
  private avatarData = new Subject<AvatarData>();
  private initialButtons = new Subject<StartButtons>();
  private tenantDataLocal!: TenantData;
  private dataParamsLocal!: UrlParams;
  constructor(private http: HttpClient) { }

  queryAllData(params: UrlParams) {
    this.subscribeTenantData(params.idCasoDeUso);
    this.setDataParams(params)
  }

  private subscribeTenantData(idCasoDeUso: string) {
    this.getTenantData(idCasoDeUso).subscribe({
      next: (res: TenantData) => {
        this.setTenantData(res);
        this.subscribeAvatarData(res.data.personaDigital.idAvatar)
      },
      error: (err: any) => {
        console.error("Error consultando datos del tenant:", err);
      }
    });
  }

  private setTenantData(res: TenantData) {
    this.dataTenant.next(res);
    this.tenantDataLocal = res;
  }

  getTenantDataLocal(): TenantData {
    return this.tenantDataLocal;
  }

  private getTenantData(idCasoDeUso: string) {
    return this.http.get<TenantData>(`${this.urlDatosTenant}${idCasoDeUso}`);
  }

  private subscribeAvatarData(idAvatar: string) {
    this.getAvatarData(idAvatar).subscribe({
      next: (res: AvatarData) => {
        this.setAvatarData(res);
      }, error: (err) => {
        console.error(err);
      }
    }).add(this.subscribeBotonesInicio)
  }
  private subscribeBotonesInicio = () => {
    this.getBotonesInicio(this.dataParamsLocal.flujo === 'todos',
      this.dataParamsLocal.idCasoDeUso, this.dataParamsLocal.idFlujo).subscribe({
        next: (res: StartButtons) => {
          this.setInitialButtons(res);
        }, error: (err) => {
          console.error(err);
        }
      });
  }
  getDatosTenantEvent(): Observable<TenantData> {
    return this.dataTenant.asObservable();
  }

  private setDataParams(params: UrlParams) {
    this.dataParams.next(params);
    this.dataParamsLocal = params;
  }

  getDataParamsEvent(): Observable<UrlParams> {
    return this.dataParams.asObservable();
  }

  getBotonesInicio(todos: boolean, useCaseId: string, flujo: string) {
    if (todos) {
      return this.http.get<StartButtons>(`${this.urlBotonesInicioTodos}${useCaseId}`);
    }
    return this.http.get<StartButtons>(`${this.urlBotonesInicio}${flujo}`);
  }

  getConversacion(id: string) {
    return this.http.get(`${this.urlDescargarConversación}${id}/pdf`);
  }

  getAvatarData(id: string) {
    return this.http.get<AvatarData>(`${this.urlAvatarData}${id}`);
  }

  getAvatarDataLocal(): Observable<AvatarData> {
    return this.avatarData.asObservable()
  }

  getInitialButtonsEvent(): Observable<StartButtons> {
    return this.initialButtons.asObservable()
  }

  private setAvatarData(aData: AvatarData) {
    this.avatarData.next(aData);
  }

  private setInitialButtons(res: StartButtons) {
    this.initialButtons.next(res)
  }

}
