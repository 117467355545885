import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UnityControllerService } from '../unity-controller.service';
import { saveAs } from 'file-saver';
import { WarningMessage } from 'src/app/components/warning-modal/warning-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UseCasesService {
  private _onBeforeActionKeys: { [key: string]: string } = {
    'printCupon': 'onBeforePrintCupon',
    'printDuplicate': 'onBeforePrintDuplicate',
    'showImage': 'onBeforeShowImage',
    'showYoutubeVideo': 'onBeforeShowYoutubeVideo',
    'showAnimation': 'onBeforeShowAnimation',
    'CintaLed': 'onBeforeCintaLed',
    'startWebcam': 'onBeforeStartWebcam',
    'showProductList': 'onBeforeShowProductList',
    'showLinkBox': 'onBeforeShowLinkBox',
    'showUserInputField': 'onBeforeShowUserInputField',
    'waitTime': 'onBeforeWaitTime',
    'CaptureKeyboardInput': 'onBeforeCaptureKeyboardInput',
    'DontCaptureKeyboardInput': 'onBeforeDontCaptureKeyboardInput',
    'changeCamera': 'onBeforeChangeCamera',
    'show3DObject': 'onBeforeShow3DObject',
    'downloadPDFFile': 'onBeforeDownloadFile',
    'changePrintSettings': 'onBeforeChangePrintSettings',
    'printPDFFile': 'onBeforePrintPDFFile',
    'printDocument': 'onBeforePrintDocument',
    'virtualKeyboard': 'onBeforeVirtualKeyboard',
    'virtualNumericKeyboard': 'onBeforeVirtualNumericKeyboard',
    'mostrarTablaTurnos': 'onBeforeMostrarTablaTurnos'
  }

  private _onAfterActionKeys: { [key: string]: string } = {
    'showAdditionalTextBoxes': 'onAfterShowAdditionalTextBoxes',
    'numericKeyBoard': 'onAfterNumericKeyBoard',
    'qwertyKeyboard': 'onAfterQwertyKeyboard',
    'showLoading': 'onAfterShowLoading',
    'endConversation': 'onAfterEndConversation',
    'recognizeFace': 'onAfterRecognizeFace',
    'addUser': 'onAfterAddUser',
    'getUserName': 'onAfterGetUserName',
    'disableMicrophone': 'onAfterDisableMicrophone',
    'enableMicrophone': 'onAfterEnableMicrophone',
    'showMicrophoneTooltip': 'onAfterShowMicrophoneTooltip',
    'showModalAddress': 'onAfterShowModalAddress'
  }

  // Subjects to emit events
  private eventStopConversation = new Subject<boolean>();
  private eventShowModalAddress = new Subject<boolean>();
  private eventShowLoading = new Subject<string>();
  private micState = new Subject<number>();
  private eventShowMicrophoneTooltip = new Subject<boolean>();
  private addressMessage = new Subject<string>();
  private eventVirtualNumericKeyboard = new Subject<any>();
  private printDocument = new Subject<Blob>();
  private documentType = new Subject<string>();
  private flowProtected = new Subject<string>();
  private keyFlowVerified = new Subject<void>();
  private addNewShift = new Subject<Subject<string>>();
  private addNewShiftToTable = new Subject<string>();
  private showWarningModal = new Subject<WarningMessage>();

  // Virtual Keyboard ==========================================================
  private virtualKeyboard = new Subject<boolean>();
  private virtualKeyboardMessage = new Subject<string>();
  private virtualKeyboardSendMessage = new Subject<boolean>();

  // Virtual Numeric Keyboard ==================================================
  private virtualNumericKeyboard = new Subject<boolean>();
  private virtualNumericKeyboardMessage = new Subject<string>();
  private virtualNumericKeyboardSendMessage = new Subject<boolean>();

  constructor(private unityControllerService: UnityControllerService) { }

  public separateActions(actions: any[]) {
    if (actions.length === 0) {
      return {
        onBeforeActions: [],
        onAfterActions: [],
      };
    }

    const onBeforeActions: any[] = [];
    const onAfterActions: any[] = [];
    actions.forEach(action => {
      if (this._onBeforeActionKeys[action.accion]) {
        onBeforeActions.push(action);
      } else if (this._onAfterActionKeys[action.accion]) {
        onAfterActions.push(action);
      }
    });

    return {
      onBeforeActions,
      onAfterActions
    }
  }

  public async executeOnBeforeActions(actions: any[]) {
    actions.forEach(action => {
      switch (action.accion) {
        case 'printCupon':
          break;
        case 'printDuplicate':
          break;
        case 'showImage':
          break;
        case 'showYoutubeVideo':
          break;
        case 'CintaLed':
          break;
        case 'startWebcam':
          break;
        case 'showProductList':
          break;
        case 'showLinkBox':
          break;
        case 'showUserInputField':
          break;
        case 'waitTime':
          break;
        case 'CaptureKeyboardInput':
          break;
        case 'DontCaptureKeyboardInput':
          break;
        case 'changeCamera': {
          const cameraMov = action.cameraMov;
          this.unityControllerService.sendChangeCamera(cameraMov);
          break;
        }
        case 'show3DObject': {
          const objectToShow = action.object3D;
          this.unityControllerService.sendShow3DObject(objectToShow);
          break;
        }
        case 'downloadPDFFile': {
          const base64StringPDF = '';
          const byteCharactersPDF = atob(base64StringPDF);
          const byteNumbersPDF = new Array(byteCharactersPDF.length);
          for (let i = 0; i < byteCharactersPDF.length; i++) {
            byteNumbersPDF[i] = byteCharactersPDF.charCodeAt(i);
          }
          const byteArrayPDF = new Uint8Array(byteNumbersPDF);
          const blobPDF = new Blob([byteArrayPDF], { type: 'application/pdf' });
          saveAs(blobPDF, 'archivoNati.pdf');
          break;
        }
        case 'changePrintSettings': {
          const printType = action.type;
          localStorage.setItem('printType', printType);
          break;
        }
        case 'printPDFFile': {
          const base64StringPDFFile = action.bufferBase64;
          const byteCharactersPDFFile = atob(base64StringPDFFile);
          const byteNumbersPDFFile = new Array(byteCharactersPDFFile.length);
          for (let i = 0; i < byteCharactersPDFFile.length; i++) {
            byteNumbersPDFFile[i] = byteCharactersPDFFile.charCodeAt(i);
          }
          const byteArrayPDFFile = new Uint8Array(byteNumbersPDFFile);
          const blobPDFFile = new Blob([byteArrayPDFFile], { type: 'application/pdf' });

          const printTypeFile = localStorage.getItem('printType');

          saveAs(blobPDFFile, `epmdocument-${printTypeFile}.pdf`);
          break;
        }
        case 'printDocument': {
          const type = action.type;
          const base64StringDocument = action.bufferBase64;
          const byteCharactersDocument = atob(base64StringDocument);
          const byteNumbersDocument = new Array(byteCharactersDocument.length);
          for (let i = 0; i < byteCharactersDocument.length; i++) {
            byteNumbersDocument[i] = byteCharactersDocument.charCodeAt(i);
          }
          const byteArrayDocument = new Uint8Array(byteNumbersDocument);
          const blobDocument = new Blob([byteArrayDocument], { type: 'application/pdf' });

          this.printDocument.next(blobDocument);
          this.documentType.next(type);
          break;
        }
        case 'virtualKeyboard':
          this.setVirtualKeyboardState(true);
          break;
        case 'virtualNumericKeyboard':
          this.setVirtualNumericKeyboardState(true);
          break;
        case 'mostrarTablaTurnos':
          this.addNewShift.next(this.addNewShiftToTable);
          break;
        default:
          break;
      }
    });
  }

  public async executeOnAfterActions(actions: any[]) {

    actions.forEach(action => {
      switch (action.accion) {
        case 'showAdditionalTextBoxes':
          break;
        case 'numericKeyBoard':
          break;
        case 'qwertyKeyboard':
          break;
        case 'showLoading':
          this.eventShowLoading.next('si');
          break;
        case 'endConversation':
          this.eventStopConversation.next(true);
          this.setMicState(2);
          break;
        case 'recognizeFace':
          break;
        case 'addUser':
          break;
        case 'getUserName':
          break;
        case 'disableMicrophone':
          this.setMicState(-1);
          break;
        case 'enableMicrophone':
          this.setMicState(1);
          break;
        case 'showMicrophoneTooltip':
          this.eventShowMicrophoneTooltip.next(true);
          break;
        case 'showModalAddress':
          this.eventShowModalAddress.next(true);
          break;
        default:
          break;
      }
    });
  }

  // Virtual Keyboard ==========================================================
  public setVirtualKeyboardState(state: boolean) {
    this.virtualKeyboard.next(state);
  }
  public getVirtualKeyboardState(): Observable<boolean> {
    return this.virtualKeyboard.asObservable();
  }

  public setVirtualKeyboardMessage(message: string) {
    this.virtualKeyboardMessage.next(message);
  }
  public getVirtualKeyboardMessage(): Observable<string> {
    return this.virtualKeyboardMessage.asObservable();
  }

  public setVirtualKeyboardSendMessage() {
    this.virtualKeyboardSendMessage.next(true);
  }
  public getVirtualKeyboardSendMessage(): Observable<boolean> {
    return this.virtualKeyboardSendMessage.asObservable();
  }
  // Virtual Keyboard ==========================================================

  // Virtual Numeric Keyboard ==================================================
  public setVirtualNumericKeyboardState(state: boolean) {
    this.virtualNumericKeyboard.next(state);
  }
  public getVirtualNumericKeyboardState(): Observable<boolean> {
    return this.virtualNumericKeyboard.asObservable();
  }

  public setVirtualNumericKeyboardMessage(message: string) {
    this.virtualNumericKeyboardMessage.next(message);
  }
  public getVirtualNumericKeyboardMessage(): Observable<string> {
    return this.virtualNumericKeyboardMessage.asObservable();
  }

  public setVirtualNumericKeyboardSendMessage() {
    this.virtualNumericKeyboardSendMessage.next(true);
  }
  public getVirtualNumericKeyboardSendMessage(): Observable<boolean> {
    return this.virtualNumericKeyboardSendMessage.asObservable();
  }

  public setEventVirtualNumericKeyboard(data: any) {
    this.eventVirtualNumericKeyboard.next(data);
  }
  public getEventVirtualNumericKeyboard(): Observable<any> {
    return this.eventVirtualNumericKeyboard.asObservable();
  }
  // Virtual Numeric Keyboard ==================================================

  // Observable to know the state of the microphone
  public setMicState(state: number) {
    this.micState.next(state);
  }

  public getMicState(): Observable<number> {
    // State -1: Disabled microphone
    // State 0: Not recognizing
    // State 1: Recognizing
    // State 2: End conversation
    // State 3: Manual not recognizing

    return this.micState.asObservable();
  }

  // Observable to know when the microphone tooltip has to be shown
  public setShowMicrophoneTooltip(state: boolean) {
    this.eventShowMicrophoneTooltip.next(state);
  }

  public getShowMicrophoneTooltip(): Observable<boolean> {
    return this.eventShowMicrophoneTooltip.asObservable();
  }

  // Observable to know when the conversation has ended
  public getStopConversation(): Observable<boolean> {
    return this.eventStopConversation.asObservable();
  }
  public setStopConversation(state: boolean) {
    this.eventStopConversation.next(state);
  }

  // Functions to show the modal address, and send the address to the chat
  public getShowModalAddress(): Observable<boolean> {
    return this.eventShowModalAddress.asObservable();
  }
  public getAddressMessage(): Observable<string> {
    return this.addressMessage.asObservable();
  }
  public setAddressMessage(message: string) {
    this.addressMessage.next(message);
  }

  public getShowLoading(): Observable<string> {
    return this.eventShowLoading.asObservable();
  }

  public setShowLoading(message: string) {
    this.eventShowLoading.next(message);
  }

  // Read from the archive json in src and return object
  public async getDataMunicipiosAntioquia(): Promise<any> {
    const response = await fetch('../../assets/data/municipios-antioquia.json');
    const json = await response.json();
    return json;
  }

  public getPrintDocument(): Observable<Blob> {
    return this.printDocument.asObservable();
  }

  public getDocumentType(): Observable<string> {
    return this.documentType.asObservable();
  }

  public getFlowProtected(): Observable<string> {
    return this.flowProtected.asObservable()
  }

  public callFlowProtected(flowId: string) {
    this.flowProtected.next(flowId)
  }

  public getKeyFlowVerified(): Observable<void> {
    return this.keyFlowVerified.asObservable()
  }

  public callKeyFlowVerified() {
    this.keyFlowVerified.next()
  }
  public getAddNewShift(): Observable<Subject<string>> {
    return this.addNewShift.asObservable();
  }

  public getAddNewShiftToTable(): Observable<string> {
    return this.addNewShiftToTable.asObservable();

  }

  public getShowWarningModal() {
    return this.showWarningModal.asObservable();
  }

  public setShowWarningModal() {
    this.showWarningModal.next({ title: 'Warning', message: 'This is a warning message This is a warning message This is a warning message This is a warning message' });
  }
}

