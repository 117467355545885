import { Injectable } from '@angular/core';
import { QueueProcessorService } from './chat/queue-processor.service';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UrlParams } from 'src/app/models/parameters.model';
import { TranslateService } from '@ngx-translate/core';
import { DatumButtons } from '../models/tenant-data.model';
import { Conversation } from '../models/conversation.model';
import { TranslatorService } from './translator.service';

declare global {
  interface Window { SendLanguageChange(langVoice: string): void; }
  interface Window { receiveAvatarLoadedUnityController(): void; }
}

declare const window: Window & typeof globalThis;

interface addressInfo {
  idDepartamento: string;
  idMunicipio: string;
  direccion: string;
}

const startDictionary: { [key: string]: string } = {
  "es": "Hola",
  "en": "Hello",
  "fr": "Salut",
  "pt": "Olá",
  "de": "Hallo",
  "it": "Ciao",
  "zh-Hans": "你好"
}
@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private gptStartMessage = "Hola";
  private watsonStartFlow!: string;
  private socket: any;
  private fullData: any;
  private languageCode?: string = 'es';
  private tenantG!: string;
  private canalG!: string;
  private tokenG!: string;
  private ubicationG!: string;
  private idSession: string | null = null;
  private idClienteG = '0';

  private _isEndConversation!: boolean;
  private _isFirstMessage = true;
  private _isConnection!: boolean;
  private _isConnected = false;
  private _connectedFirstTime = false;

  private ChatGPT = false;
  private isSpeaker = false;

  private jsonMessageWS: any;
  private eventConnection = new Subject<boolean>();
  private endConversation = new Subject<void>();
  private eventServiceUnavailable = new Subject<boolean>();
  private eventChatState = new Subject<boolean>();
  private maxSessionsState = new Subject<boolean>();

  public paramsWS!: UrlParams;

  private addressInfo: addressInfo = {
    idDepartamento: "5",
    idMunicipio: "0",
    direccion: "0",
  };

  constructor(
    private queueProcessorService: QueueProcessorService,
    private translate: TranslateService,
    private translatorService: TranslatorService
  ) {
    // Get the language code from the browser
    const lang = this.translate.getBrowserLang();
    this.languageCode = lang;
    // Get the language code from the service
    this.translate.onLangChange.subscribe((event: any) => {
      this.languageCode = event.lang;
      this.gptStartMessage = startDictionary[this.languageCode!];
      const langVoice = translatorService.getVoiceCode();
      try {
        window.SendLanguageChange(langVoice);
      } catch (error) {
        return;
      }
    });

    window.receiveAvatarLoadedUnityController = () => {
      this.setChatState(true);
    }
  }

  public setEndConversation(endConversationStatus: boolean) {
    if (this._isFirstMessage) {
      return;
    }

    this._isEndConversation = endConversationStatus;
    this.SendMessageThroughSocket("");
    this.endConversation.next();
  }

  public getEndConversationEvent(): Observable<void> {
    return this.endConversation.asObservable();
  }

  public getTokenEvent(): Observable<any> {
    return this.eventConnection.asObservable();
  }

  public getServiceUnavailableEvent(): Observable<any> {
    return this.eventServiceUnavailable.asObservable();
  }

  /* ------ Websocket Connection ------ */
  public setUpWs(paramsWS: UrlParams, reconnection = false) {
    this.setChatState(true);
    this.paramsWS = paramsWS;
    this.tenantG = paramsWS.tenant;
    const tokenLocalStorage = localStorage.getItem('token') || environment.webSocket.token;
    this.tokenG = tokenLocalStorage;
    this.canalG = paramsWS.canal;
    this.ubicationG = paramsWS.ubicacion || '';
    this.idSession = reconnection ? sessionStorage.getItem('idSession') : null;

    if (!this._isConnected) {
      // const urlWebSocket = this.isShiftApp ? "https://nati-turnos-dllo.azurewebsites.net/" : environment.webSocket.url
      const urlWebSocket = environment.webSocket.url
      this.socket = new WebSocket(urlWebSocket, "websocket");
      this._isConnected = true;
      this._isConnection = true;

      const sendMessage = () => {
        if (this.socket.readyState === WebSocket.OPEN) {
          this._isEndConversation = false;
          this.SendMessageThroughSocket("");
          this.setChatState(true);
        } else {
          setTimeout(sendMessage, 100);
        }
      };

      this.socket.onopen = () => {
        sendMessage();
      };

      this.socket.onmessage = (data: { data: any }) => {
        sessionStorage.setItem('idSession', JSON.parse(data.data).idSession);
        this.fullData = data.data;
        const dataParsed = JSON.parse(this.fullData);

        // if (this.checkShiftMessage(dataParsed)) return;

        if (dataParsed.estado === 'sesiones excedidas') {
          this.setMaxSessionsState(true);
        }

        if (this._isConnection) {
          this._isConnection = false;

          if (!this._connectedFirstTime) {
            this.eventConnection.next(true);

            this._connectedFirstTime = true;
          }
        } else {
          // const dataParsed = JSON.parse(this.fullData);
          const tokenReceived = dataParsed.metadata.token;
          localStorage.setItem('token', tokenReceived);
          this.tokenG = tokenReceived;
          this.handlerMessageIncoming(dataParsed);
        }
      };

      this.socket.onclose = () => {
        this.socket = null;
        this._isConnected = false;
        this._isConnection = false;
        this.setUpWs(this.paramsWS, true);
      };
    }
  }

  public restartConversation() {
    this.setChatState(true);
    this._isFirstMessage = true;
    this._isEndConversation = false;
    const message = this.ChatGPT ? this.gptStartMessage : this.watsonStartFlow;
    this.SendMessageThroughSocket(message);
  }

  public startConversation(data: DatumButtons) {
    this.ChatGPT = data.tipoNLP === "llm";
    this.isSpeaker = data.tipoNLP === "none"
    let message = "";
    this.paramsWS.flujo = data.textoBoton;
    this.paramsWS.idFlujo = data.idFlujo;

    if (this.ChatGPT) {
      message = this.gptStartMessage;

    } else {
      this.watsonStartFlow = data.intencion
      message = data.intencion;
    }

    if (this._isConnected) {
      this.SendMessageThroughSocket(message);
    }
  }

  public handlerMessageIncoming(jsonData: Conversation) {
    this.setChatState(true);
    this.idClienteG = jsonData.metadata.idCliente;
    this.queueProcessorService.addToQueue(jsonData);
    if (jsonData?.conversation![0].detail!.length > 0) {
      if (jsonData.conversation![0].detail![0].accion === "showModalServiceUnavailable") {
        this.eventServiceUnavailable.next(true);
      }
    }
  }

  public SendMessageThroughSocket(messageToSend: string) {
    this.setChatState(false);
    if (this._isConnection) {
      this.jsonMessageWS = this.getOpenSessionData();
      this._isConnected = true;
    } else if (this.ChatGPT) {
      if (this._isFirstMessage) {
        this.jsonMessageWS = this.getStartChatGPTConversationData(messageToSend);
        this._isFirstMessage = false;
      } else if (this._isEndConversation) {
        this.jsonMessageWS = this.getStopChatGPTConversationData();
        this._isEndConversation = false;
        this._isFirstMessage = true;
      } else {
        this.jsonMessageWS = this.getChatGPTConversationData(messageToSend);
        window.totalSTT_Time = 0;
      }
    } else if (this._isFirstMessage) {
      this.jsonMessageWS = this.getStartConversationData(messageToSend);
      this._isFirstMessage = false;
    } else if (this._isEndConversation) {
      this.jsonMessageWS = this.getStopConversationData();
      this._isEndConversation = false;
      this._isFirstMessage = true;
    } else {
      this.jsonMessageWS = this.getConversationData(messageToSend);

      window.totalSTT_Time = 0;
    }
    if (this.socket) {
      this.socket.send(this.jsonMessageWS);
    }
  }

  private getOpenSessionData(): string {
    return JSON.stringify({
      event: "openSession",
      data: {
        tenant: this.tenantG,
        canal: this.canalG,
        token: this.tokenG,
        useCase: this.paramsWS.casoDeUso,
        flujo: this.paramsWS.flujo,
        ubicacion: this.ubicationG,
        idFlujo: this.paramsWS.idFlujo,
        idUseCase: this.paramsWS.idCasoDeUso,
        idSession: this.idSession,
      },
    });
  }

  private getStartChatGPTConversationData(messageToSend: string): string {
    return JSON.stringify({
      event: "startChatGPTConversation",
      data: {
        tenant: this.tenantG,
        canal: this.canalG,
        token: this.tokenG,
        useCase: this.paramsWS.casoDeUso,
        flujo: this.paramsWS.flujo,
        idFlujo: this.paramsWS.idFlujo,
        idUseCase: this.paramsWS.idCasoDeUso,
        ubicacion: this.ubicationG,
        language: this.languageCode,
        message: messageToSend,
      },
    });
  }

  private getStopChatGPTConversationData(): string {
    return JSON.stringify({
      event: "stopChatGptConversation",
      data: {
        idCliente: this.idClienteG,
        tenant: this.tenantG,
        canal: this.canalG,
        token: this.tokenG,
        useCase: this.paramsWS.casoDeUso,
        flujo: this.paramsWS.flujo,
        idFlujo: this.paramsWS.idFlujo,
        idUseCase: this.paramsWS.idCasoDeUso,
        ubicacion: this.ubicationG,
      },
    });
  }

  private getChatGPTConversationData(messageToSend: string): string {
    let timeSTTG = window.totalSTT_Time;
    timeSTTG = Math.ceil(timeSTTG);

    return JSON.stringify({
      event: "chatGptConversation",
      data: {
        idCliente: this.idClienteG,
        tenant: this.tenantG,
        canal: this.canalG,
        token: this.tokenG,
        useCase: this.paramsWS.casoDeUso,
        flujo: this.paramsWS.flujo,
        idFlujo: this.paramsWS.idFlujo,
        idUseCase: this.paramsWS.idCasoDeUso,
        ubicacion: this.ubicationG,
        language: this.languageCode,
        message: messageToSend,
        tiempoStt: timeSTTG,
      }
    });
  }

  private getStartConversationData(messageToSend: string): string {
    return JSON.stringify({
      event: this.isSpeaker ? "startSpeaker" : "startConversation",
      data: {
        tenant: this.tenantG,
        canal: this.canalG,
        token: this.tokenG,
        useCase: this.paramsWS.casoDeUso,
        flujo: this.paramsWS.flujo,
        idFlujo: this.paramsWS.idFlujo,
        idUseCase: this.paramsWS.idCasoDeUso,
        ubicacion: this.ubicationG,
        language: this.languageCode,
        transaction: messageToSend,
        officeInfo: {
          nameLocation: "",
          idLocation: "",
        },
      },
    });
  }

  private getStopConversationData(): string {
    return JSON.stringify({
      event: "stopConversation",
      data: {
        idCliente: this.idClienteG,
        tenant: this.tenantG,
        canal: this.canalG,
        token: this.tokenG,
        useCase: this.paramsWS.casoDeUso,
        flujo: this.paramsWS.flujo,
        idFlujo: this.paramsWS.idFlujo,
        idUseCase: this.paramsWS.idCasoDeUso,
        ubicacion: this.ubicationG,
      },
    });
  }

  private getConversationData(messageToSend: string): string {
    let timeSTTG = window.totalSTT_Time;
    timeSTTG = Math.ceil(timeSTTG);
    const { idDepartamento, idMunicipio, direccion } = this.addressInfo

    return JSON.stringify({
      event: "conversation",
      data: {
        idCliente: this.idClienteG,
        tenant: this.tenantG,
        canal: this.canalG,
        token: this.tokenG,
        useCase: this.paramsWS.casoDeUso,
        flujo: this.paramsWS.flujo,
        ubicacion: this.ubicationG,
        idFlujo: this.paramsWS.idFlujo,
        idUseCase: this.paramsWS.idCasoDeUso,
        language: this.languageCode,
        message: messageToSend,
        tiempoStt: timeSTTG,
        officeInfo: {
          nameLocation: "",
          idLocation: "",
        },
        addressInfo: {
          idDepartamento: idDepartamento,
          idMunicipio: idMunicipio,
          direccion: direccion,
        },
      },
    });
  }

  public setChatState(chatState: boolean) {
    this.eventChatState.next(chatState);
  }

  public getChatState(): Observable<any> {
    return this.eventChatState.asObservable();
  }

  public setAddresInfo(addressInfo: addressInfo) {
    this.addressInfo = addressInfo;
  }

  public sendAddressInfo() {
    this.SendMessageThroughSocket(this.addressInfo.direccion);
  }

  public getIdCliente(): string {
    return this.idClienteG;
  }

  public setMaxSessionsState(maxSessionsState: boolean) {
    this.maxSessionsState.next(maxSessionsState);
  }

  public getMaxSessionsState(): Observable<any> {
    return this.maxSessionsState.asObservable();
  }

  // private checkShiftMessage(dataParsed: any) {
  //   if (dataParsed.event === "turno") {
  //     const shiftData = dataParsed.data
  //     const shiftConversation: Conversation = {
  //       metadata: {
  //         token: '',
  //         idCliente: ''
  //       },
  //       conversation: [
  //         {
  //           speech: `turno ${shiftData.turno}, taquilla ${shiftData.modulo}, nombre ${shiftData.nombre_usuario}`,
  //           text: "",
  //           expressions: "happy",
  //           movements: "talk"
  //         }
  //       ]
  //     }
  //     this.queueProcessorService.addToQueue(shiftConversation);
  //     return true;
  //   }
  //   return false;
  // }
}

