<div id="container-ui" #containerDiv>
  <!-- Vista de la aplicación inicial (Cambio de idioma, botones de inicio) -->
  <div id="start-app" [ngClass]="elderAdultLayout ? 'start-app-elder' : 'start-app'" #startApp
    [@fadeIn]="startAppStatus" [@fadeOut]="startAppStatus" *ngIf="!isSingleFlow">
    <div class="switchlang-box">
      <app-switchlang *ngIf="startAppStatus === 'visible'" [elderLayout]="elderAdultLayout"></app-switchlang>
    </div>
    <div class="initial-buttons"
      [ngStyle]="{'justify-content': initialButtons.data.length < 4 ? 'center' : 'flex-start'}"
      *ngIf="!tenantBlancox && !noAvatar && isWebChannel">
      <button type="button" class="btn shadow-sm"
        [ngClass]="initialButtons.data.length === 1 ? 'start-button' : 'web-multiple-start-button'"
        *ngFor="let initialButton of initialButtons.data" (click)="checkFlowSecurity(initialButton)">
        {{initialButton.textoBoton}}
      </button>
    </div>
    <div class="initial-buttons"
      [ngStyle]="{'justify-content': initialButtons.data.length < 4 ? 'center' : 'flex-start'}"
      *ngIf="!tenantBlancox && !noAvatar && !isWebChannel">
      <button type="button" class="btn shadow-sm"
        [ngClass]="{'start-button': initialButtons.data.length === 1, 'multiple-start-button': initialButtons.data.length > 1, 'elder-font' : elderAdultLayout}"
        *ngFor="let initialButton of initialButtons.data" (click)="checkFlowSecurity(initialButton)">
        {{initialButton.textoBoton}}
      </button>
    </div>
    <div class="initial-buttons-noAvatar" *ngIf="!tenantBlancox && noAvatar && !isWebChannel">
      <button type="button" class="btn shadow-sm"
        [ngClass]="initialButtons.data.length === 1 ? 'start-button' : 'multiple-start-button'"
        *ngFor="let initialButton of initialButtons.data" (click)="checkFlowSecurity(initialButton)">
        {{initialButton.textoBoton}}
      </button>
    </div>
    <div class="initial-buttons-noAvatar" *ngIf="!tenantBlancox && noAvatar && isWebChannel">
      <button type="button" class="btn shadow-sm"
        [ngClass]="initialButtons.data.length === 1 ? 'start-button' : 'multiple-start-button'"
        *ngFor="let initialButton of initialButtons.data" (click)="checkFlowSecurity(initialButton)">
        {{initialButton.textoBoton}}
      </button>
    </div>

    <div class="initial-buttons"
      [ngStyle]="{'justify-content': initialButtons.data.length === 1 ? 'center' : 'flex-start'}" *ngIf="tenantBlancox">
      <button type="button" class="btn buja-button shadow-sm" *ngFor="let initialButton of initialButtons.data"
        (click)="checkFlowSecurity(initialButton)">
        Clic para iniciar
      </button>
    </div>
  </div>
  <!-- Vista de la aplicación inicial (Cambio de idioma, botones de inicio) -->


  <!-- Vista de la aplicación (Controles, Chat) -->
  <div id="ui" #ui [@fadeIn]="uiStatus" [@fadeOut]="uiStatus">
    <div class="ui-items">
      <div class="container-chat" *ngIf="showChat">
        <app-chat-section [elderLayout]="elderAdultLayout" [isSingleFlow]="isSingleFlow" [noAvatar]="noAvatar"
          [mainFlow]="initialFlow" [isWatson]="!isGpt"></app-chat-section>
      </div>
      <div class="controls-section" *ngIf="showChat">
        <app-controls-section [ui]="uiObservable" [isTodosFlow]="!isSingleFlow"></app-controls-section>
      </div>
    </div>
  </div>
  <!-- Vista de la aplicación (Controles, Chat) -->
</div>
<app-pdf-viewer-modal></app-pdf-viewer-modal>
<app-modal-address></app-modal-address>
<app-warning-modal></app-warning-modal>
<app-flow-key-modal [urlRedirect]="exitRedirectUrl" [showQuitModal]="!isSingleFlow"></app-flow-key-modal>